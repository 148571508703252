import React from 'react'
import { Participate } from '../Components/Participate'
import Anleitung from '../Components/Anleitung'
import { Container, Image } from '@mantine/core'
import TitleText from '../Components/TitleText';

export default function Engagieren() {

  interface MarkdownObject {
    title: string;
    description: string | React.ReactElement;
    button?: {
        text: string;
        onClick: () => void;
        color?: string;
        size?: string;
        variant?: string;
        bg?:string;
        radius?:string;
        link:string;
      };
      image?: React.ReactElement;
  }

  const mockdata : MarkdownObject [] = [
    {
      title: 'Schritt für Schritt: von der Anmeldung bis in den Klassenraum',
      description:
      <Anleitung/>
    },
    {
      title: 'Du möchtest unterrichten?',
      description: <div dangerouslySetInnerHTML={{ __html:"<p>Du möchtest dich sozial engagieren, einem starken Netzwerk anschließen und Leistungspunkte für dein Studium sammeln?</p> <b>Dann schließ dich uns an und werde Teil des Teams !</b> <p>Wir sind eine Gruppe von engagierten Studierenden, die sich für ein stärkeres Miteinander einsetzen.</p>" }} />,
      button: {
        text: 'Als Sprachhelfer*in anmelden!',
        onClick: () => console.log('Button clicked'),
        color: 'dark.6',
        size: 'sm',
        variant: 'filled',
        radius: 'xl',
        link:"/engagieren/lehrer"
      },
      image: <Image src='./team.svg' />

    }


    
  ];

  return (
   <>
    <Container py="xl">
    <TitleText title="Werde Teil eines großen Netzwerks!" subtitle="Melde dich als Sprachhelfer*in an." />
      <Participate data={mockdata}/>
    </Container>
   </>
  )
}
