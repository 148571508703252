import React from 'react'
import Anleitung from '../Components/Anleitung'
import { Container } from '@mantine/core'
import { AboutUs } from '../Components/AboutUs'

export default function About() {
  return (
    <>
    <Container >
     <AboutUs/>
    </Container>
     
    </>
  )
}
