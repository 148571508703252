
import React from 'react';
import NewsCard from './NewsCard';

import { SimpleGrid, Container } from '@mantine/core';


interface News {
    id: number;
    title: string;
    image: string;
    date: string;
    shortText: string;
    longText: string;
  }

interface NewsListProps {
  news: News[];
}

const NewsList: React.FC<NewsListProps> = ({ news }) => {
  const cards = news.map((news) => <NewsCard key={news.id} news={news} />);

  return (
    <Container py="xl">
      <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        {cards}
      </SimpleGrid>
    </Container>
  );
};

export default NewsList;