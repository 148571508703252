export interface News {
    id: number;
    title: string;
    image: string;
    date: string;
    shortText: string;
    longText: string;
  }
  
  const newsData: News[] = [
    {
      id: 1,
      title: 'Veranstaltung 1',
      image: 'https://assets.deutschlandfunk.de/FILE_a1acdee19bc24126b5ea86eb8ae024d7/1920x1080.jpg?t=1597591420827',
      date: '2023-05-01',
      shortText: 'Kurztext 1',
      longText: 'Langertext 1',
    },
    {
        id: 2,
        title: 'Veranstaltung 2',
        image: 'https://www.fu-berlin.de/sites/startseite-test/inhalte/intl-menschen.jpg?width=1300',
        date: '2023-05-01',
        shortText: 'Kurztext 1',
        longText: 'Langertext 1',
      },
    // Weitere Veranstaltungen hier hinzufügen
  ];
  
  export default newsData;