import React from 'react';
import {
    createStyles,
    Text,
    Title,
    SimpleGrid,
    TextInput,
    Textarea,
    Button,
    Group,
    ActionIcon,
    rem,
    Container
  } from '@mantine/core';
  import { BrandTwitter, BrandYoutube, BrandInstagram } from "tabler-icons-react"
  import { ContactIconsList } from './ContactIcons';
  import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { Notifications } from '@mantine/notifications';
import TitleText from './TitleText';
  
  const useStyles = createStyles((theme) => ({
    wrapper: {
      minHeight: 400,
      boxSizing: 'border-box',
      marginTop: '30px',
      backgroundImage: `linear-gradient(-60deg, #564d8b 0%, #726b9b 100%)`,
      borderRadius: theme.radius.md,
      padding: `calc(${theme.spacing.xl} * 2.5)`,
  
      [theme.fn.smallerThan('sm')]: {
        padding: `calc(${theme.spacing.xl} * 1.5)`,
      },
    },
  
    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      color: theme.white,
      lineHeight: 1,
    },
  
    description: {
      color: theme.colors[theme.primaryColor][0],
      maxWidth: rem(300),
  
      [theme.fn.smallerThan('sm')]: {
        maxWidth: '100%',
      },
    },
  
    form: {
      backgroundColor: theme.white,
      padding: theme.spacing.xl,
      borderRadius: theme.radius.md,
      boxShadow: theme.shadows.lg,
    },
  
    social: {
      color: theme.white,
  
      '&:hover': {
        color: theme.colors[theme.primaryColor][1],
      },
    },
  
    input: {
      backgroundColor: theme.white,
      borderColor: theme.colors.gray[4],
      color: theme.black,
  
      '&::placeholder': {
        color: theme.colors.gray[5],
      },
    },
  
    inputLabel: {
      color: theme.black,
    },
  
    control: {
      backgroundColor:'black',
      borderRadius: '30px',

      '&:hover': {
        backgroundColor: '#202020 !important',
      },
    },

    toptitle: {
        fontSize: rem(34),
        fontWeight: 900,
    
        [theme.fn.smallerThan('sm')]: {
          fontSize: rem(24),
        },
      },
    
      topdescription: {
        maxWidth: 600,
        margin: 'auto',
    
        '&::after': {
          content: '""',
          display: 'block',
          backgroundColor: '#726B9B',
          width: rem(45),
          height: rem(2),
          marginTop: theme.spacing.sm,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
  }));
  
  const social = [BrandTwitter, BrandYoutube, BrandInstagram];
  
  export default function Kontakt() {
    const { classes } = useStyles();


    const form = useForm({
      initialValues: {
        email: '',
        name: '',
        message: "",
      },
  
      validate: {
        email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
       
      },
    });

    const submit = (): void => {
      console.log(form.values)


      fetch(
        "https://sinpxmf6ml.execute-api.us-east-1.amazonaws.com/default/SendeRegistrierungsMail",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mail: "info@studentisches-forum.de",
            subject : "Kontaktanfrage", 
            nachricht : `Name: ${form.values.name} \n email: ${form.values.email} \n Nachricht: ${form.values.message}`
          }),
        }
      )

      showNotification({
        title: 'Erfolgreich',
        color: 'green',
        message: 'Deine Anfrage war erfolgreich!',
      })

      form.reset();
    }

    const icons = social.map((Icon, index) => (
        <ActionIcon key={index} size={28} className={classes.social} variant="transparent">
          <Icon size="1.4rem"  />
        </ActionIcon>
      ));
  
    return (
    <Container py="xl">
      <Notifications />

        <TitleText title=" Kontaktiere uns" subtitle=" Du hast noch offene Fragen oder bist an einer Kooperation interessiert?  Dann kontaktiere uns gerne!" />
    
    <div className={classes.wrapper}>
      <SimpleGrid cols={2} spacing={50} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        <div>
   

          <ContactIconsList variant="white" />

          <Group mt="xl">{icons}</Group>
        </div>
        <div className={classes.form}>
        <form onSubmit={form.onSubmit(() => submit())}>
          <TextInput
            label="Email"
            placeholder="deine@email.de"
            required
            {...form.getInputProps("email")}
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <TextInput
            label="Name"
            placeholder="John Doe"
            mt="md"
            {...form.getInputProps("name")}
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <Textarea
            required
            label="Deine Nachricht"
            placeholder="Hallo, ich möchte ...."
            minRows={4}
            mt="md"
            
            {...form.getInputProps("message")}
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />

          <Group position="right" mt="md">
            
            <Button className={classes.control} type="submit">Nachricht abschicken</Button>
          </Group>
          </form>
        </div>
      </SimpleGrid>
    </div>
      </Container>
    );
  }