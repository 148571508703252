import React from 'react';
import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles, Paper, Text, Title, Button, useMantineTheme, rem, Box, Badge, Flex, Space } from '@mantine/core';
import { Link } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
    card: {
      height: rem(340),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  
    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontWeight: 900,
      color: theme.white,
      lineHeight: 1.2,
      fontSize: rem(22),
      marginTop: theme.spacing.xs,
      textShadow: "3px 4px 15px #00000194"
    },
  
    text: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontWeight: 400,
      color: theme.white,
      lineHeight: 1.2,
      fontSize: rem(16),
      marginTop: theme.spacing.xs,
      textShadow: "1px 2px 3px #000000"
    },
  
    
  }));
  
interface CardProps {
  image: string;
  title: string;
  time: string;
  text: string;
  place: string;
}

function Card({ image, title, time, text, place }: CardProps) {
  const { classes } = useStyles();

  return (
    <Paper
      shadow="md"
      p="lg"
      radius="none"
      sx={{ backgroundImage: `url(${image})` }}
      className={classes.card}
    >
      <Box w="80%" px={25}>
        <Box dir='column' h="100%">
        
        <Box>
          <Badge color="dark" variant="filled" size="lg">{time}</Badge>
          
          {place && <Badge color="gray" variant="light" size="lg" mt={10}>{place}</Badge>}
          <Title order={3} className={classes.title}>
            {title}
          </Title>
          <Title order={1} className={classes.text}>
            {text}
          </Title>
        </Box>
        
        {/*}
        <Link to={`/veranstaltungen/`} style={{ textDecoration: 'none' }}>
        <Button variant="white" color="dark"  bottom="30px" pos="absolute">
         Mehr erfahren
        </Button>
       
        </Link>
         {*/}
        </Box>
      </Box>
    </Paper>
  );
}

const data = [
  {
image: `${process.env.PUBLIC_URL}/poster.png`,
title: "Talk about Agency",
text: `Mirrors | Menschen mit Fluchterfahrung - Im Gespräch mit Tareq Alaows | Eintritt frei | Anmeldung erwünscht`,
time: '10.06.2023 - 17:00 Uhr',
place: "Heinrich-Böll Bibliothek"

  },
  {
    image:
      'https://assets.deutschlandfunk.de/FILE_a1acdee19bc24126b5ea86eb8ae024d7/1920x1080.jpg?t=1597591420827',
    title: 'Get 2 Know',
    text: 'Das erste Kennenlernen findet im Cafe GalileA an der Freien Universität Berlin statt',
    time: '27.04.2023 - 16:00 Uhr',
    place: ""
  },
  {
    image:
      'https://www.fu-berlin.de/sites/startseite-test/inhalte/intl-menschen.jpg?width=1300',
    title: 'Einführungsveranstaltung',
    text: 'Die erste offizielle Einführungsveranstaltung findet bald statt. Der konkrete Termin und der Veranstaltungsort werden noch rechtzeitig bekanntgegeben. ',
    time: 'tba',
    place: ""
  },
  
];

export function News() {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const slides = data.map((item) => (
    <Carousel.Slide key={item.title}>
      <Card {...item} />
    </Carousel.Slide>
  ));

  return (
    <Carousel
      slideSize="50%"
      breakpoints={[{ maxWidth: 'md', slideSize: '100%', slideGap: rem(2) }]}
      slideGap="xl"
      align="start"
      slidesToScroll={mobile ? 1 : 2}
      mt={40}
      
     
    >
      {slides}
    </Carousel>
  );
}