import { TextInput, Paper, Text, Group, Stack, Center, Card,Container, Button, Select, Title,    createStyles,
    rem,
    Textarea,
    Checkbox,
    Box,
    Flex,  } from '@mantine/core'
import React, { useState } from 'react'
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { Notifications } from '@mantine/notifications';
import TitleText from './TitleText';

const useStyles = createStyles((theme) => ({
    title: {
      fontSize: rem(34),
      fontWeight: 900,
  
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(24),
      },
    },
  
    description: {
      maxWidth: 600,
      margin: 'auto',
  
      '&::after': {
        content: '""',
        display: 'block',
        backgroundColor: '#726B9B',
        width: rem(45),
        height: rem(2),
        marginTop: theme.spacing.sm,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  }));

export default function ParticipateTeacher():JSX.Element {
    const { classes, theme } = useStyles();

    const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>([]);


    const handleWeekdayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = event.target;
      if (checked) {
        setSelectedWeekdays([...selectedWeekdays, value]);
      } else {
        setSelectedWeekdays(selectedWeekdays.filter((weekday) => weekday !== value));
      }
      
    };
   

    const form = useForm({
        initialValues: {
          email: '',
          name: '',
          lastname: '',
          number: '',
          pref1: '',
          pref2: '',
          pref3: '',
          uni: "",
          studiengang: "",
          semester: "",
          message: "",
          dataprotection: false,


        },
    
        validate: {
          email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
      
          //password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
        },
      });

      const submit = (): void => {
        
        console.log(selectedWeekdays)


        fetch(
          "https://sinpxmf6ml.execute-api.us-east-1.amazonaws.com/default/SendeRegistrierungsMail",
          {
            method: "POST",
            mode: "no-cors",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              mail: "info@studentisches-forum.de",
              subject : "Registrierung", 
              nachricht : `Name: ${form.values.name} \n Nachname: ${form.values.lastname} \n email: ${form.values.email} \n Nummer: ${form.values.number} \n Uni: ${form.values.uni} \n Studiengang: ${form.values.studiengang} \n Semester: ${form.values.semester} \n Präferenz 1: ${form.values.pref1} \n Präferenz 2: ${form.values.pref2} \n Präferenz 3: ${form.values.pref3} \n Wochentage: ${selectedWeekdays.join(", ")} \n Nachricht: ${form.values.message}`
            }),
          }
        )
        

        showNotification({
          title: 'Erfolgreich',
          color: 'green',
          message: 'Deine Registrierung war erfolgreich!',
        })

        form.reset();

        
        setSelectedWeekdays([])
      }


  return (
    <>
     <Notifications />
    <Paper>
     
     <Container size="lg" py="xl">


    <TitleText title=" Werde Teil eines großen Netzwerks!" subtitle=" Melde dich hier als Sprachhelfer*in an!" />

       
      <form onSubmit={form.onSubmit(() => submit())}>
        <Stack>
          <Text>Bitte fülle alle Felder aus. Sollte es zu Problemen kommen, dann kannst du uns auch eine Mail an info@studentisches-forum.de schicken.</Text>
          
            <TextInput
            required
              label="Vorname"
              placeholder="Vorname"
             
              {...form.getInputProps("name")}
            
              radius="md"
            />

            <TextInput
            required
              label="Nachname"
              placeholder="Nachname"
              {...form.getInputProps("lastname")}
              radius="md"
            />
          

          <TextInput
            required
            label="Email"
            placeholder="Email-Adresse"
          
            {...form.getInputProps("email")}
        
            radius="md"
          />

        <TextInput
            required
            label="Telefonnummer"
            placeholder="Telefonnummer"
            {...form.getInputProps("number")}
            radius="md"
          />

        <TextInput
            required
            label="Universität"
            placeholder="Universität"
            {...form.getInputProps("uni")}
            radius="md"
          />
        
        <TextInput
            required
            label="Studiengang"
            placeholder="Studiengang"
            {...form.getInputProps("studiengang")}
            radius="md"
          />

        <TextInput
            required
            label="Aktuelles Fachsemester"
            placeholder="Aktuelles Fachsemester"
            {...form.getInputProps("semester")}
            radius="md"
          />
        
        <Text weight={500}>Wähle 3 Präferenzen für deinen Einsatzort</Text>
        <Box pl="lg" pr="lg" >
            
            <Select
                required
                label="Präferenz 1"
                placeholder="Präferenz 1"
                searchable
                nothingFound="Präferenz 1"
                
                {...form.getInputProps("pref1")}
                data={['Charlottenburg-Wilmersdorf', 'Friedrichshain-Kreuzberg', 'Lichtenberg', 'Marzahn-Hellersdorf', 'Mitte', 'Neukölln', 'Pankow', 'Reinickendorf', 'Spandau', 'Steglitz-Zehlendorf', 'Tempelhof-Schöneberg', 'Treptow-Köpenick']}
             
            />

            <Select
                required
                label="Präferenz 2"
                placeholder="Präferenz 2"
                searchable
                nothingFound="Präferenz 2"
                {...form.getInputProps("pref2")}
                data={['Charlottenburg-Wilmersdorf', 'Friedrichshain-Kreuzberg', 'Lichtenberg', 'Marzahn-Hellersdorf', 'Mitte', 'Neukölln', 'Pankow', 'Reinickendorf', 'Spandau', 'Steglitz-Zehlendorf', 'Tempelhof-Schöneberg', 'Treptow-Köpenick']}
            />

            <Select
                required
                label="Präferenz 3"
                placeholder="Präferenz 3"
                searchable
                nothingFound="Präferenz 3"
                {...form.getInputProps("pref3")}
                data={['Charlottenburg-Wilmersdorf', 'Friedrichshain-Kreuzberg', 'Lichtenberg', 'Marzahn-Hellersdorf', 'Mitte', 'Neukölln', 'Pankow', 'Reinickendorf', 'Spandau', 'Steglitz-Zehlendorf', 'Tempelhof-Schöneberg', 'Treptow-Köpenick']}
            />
         </Box>


         <Text weight={500}>Wähle mögliche Einsatztage</Text>
            <Box pl="lg" pr="lg">
              <Flex justify="flex-start" gap={20} wrap="wrap">
              <Checkbox
                label="Montag"
                value="Montag"
                onChange={handleWeekdayChange}
              />
              <Checkbox
                label="Dienstag"
                value="Dienstag"
                onChange={handleWeekdayChange}
              />
              <Checkbox
                label="Mittwoch"
                value="Mittwoch"
                onChange={handleWeekdayChange}
              />
              <Checkbox
                label="Donnerstag"
                value="Donnerstag"
                onChange={handleWeekdayChange}
              />
              <Checkbox
                label="Freitag"
                value="Freitag"
                onChange={handleWeekdayChange}
              />
              </Flex>
            </Box>


          <Textarea
            placeholder="Deine Nachricht an uns (optional)"
            label="Deine Nachricht an uns (optional)"
            {...form.getInputProps("message")}
            />

  

          <Checkbox
          required
                label="Ich stimme den Datenschutzrechtlinien zu"
                {...form.getInputProps("dataprotection")}
              />

          <Button variant="filled" color='dark' radius="md" type="submit">Abschicken</Button>


        </Stack>
      </form>
      </Container>
    </Paper>
    </>
  )
}
