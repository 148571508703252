import React, { useEffect, useState } from 'react';
import {
    createStyles,
    Header,
    Group,
    Button,
    Box,
    rem,
    Image,
    Burger,
    Drawer,
    ScrollArea,
    Divider,
    Flex,
    Text,
  } from '@mantine/core';
  import { useDisclosure } from '@mantine/hooks';
import { Link, useLocation } from 'react-router-dom';

  const useStyles = createStyles((theme) => ({
    link: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      paddingLeft: theme.spacing.md,
      paddingRight: theme.spacing.md,
      textDecoration: 'none',
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      fontWeight: 700,
      fontSize: 16,
      fontFamily: `Nunito, ${theme.fontFamily}`,
      borderBottom: 'solid 2px transparent',
  
      [theme.fn.smallerThan('lg')]: {
        height: rem(42),
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      },
  
      ...theme.fn.hover({
        color: "#726B9B",
        transition: "0.5s",
      }),
    },

    activelink: {
      color: "#726B9B",
    },
    
    hiddenMobile: {
      [theme.fn.smallerThan('lg')]: {
        display: 'none',
      },
    },
  
    hiddenDesktop: {
      [theme.fn.largerThan('lg')]: {
        display: 'none',
      },
    },
  }));

  interface navitem{
    title: string,
    link: string
  }
  
 
 
function Navigation() {
    const { classes, theme } = useStyles();
    const location = useLocation();

    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);

    const [scrollPosition, setScrollPosition] = useState(0);

    const links : navitem[] = [{title: "Start", link: "/start"}, {title: "Über Uns", link: "/about"}, {title: "Team", link: "/team"}, {title: "Engagiere dich", link: "/engagieren"}, {title: "Standorte", link: "/standorte"}, {title: "Kontakt", link: "/kontakt"}]

    const [pages, setPages] = useState(links)

    useEffect(() => {
      const handleScroll = () => {
        setScrollPosition(window.scrollY);
      };
    
      window.addEventListener('scroll', handleScroll);
    
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    
    return (
      <Box  h={30} >
        <Header height={60} px="md" pos="fixed" sx={{borderBottom: "none", boxShadow: scrollPosition >= 20 ? '0px 2px 25px 2px rgba(0, 0, 0, 0.10)' : 'none', transition: "0.3s"}}>
          <Group position="apart" sx={{ height: '100%' }}>
            <Link to="/start">
            <Image width={100} height={40}  src={`${process.env.PUBLIC_URL}/logo.svg`} />
            </Link>
           
  
            <Group sx={{ height: '100%' }} spacing={0} className={classes.hiddenMobile}>
            
            {pages.map((page) => {
              const isActive = location.pathname.includes(page.link);
              return  <Text key={page.title} to={page.link} component={Link} className={isActive ? `${classes.link} ${classes.activelink}` : classes.link}>
                        {page.title}
                      </Text>
            })}
             
            </Group>
  
            <Group className={classes.hiddenMobile}>
            
              <Button
                   variant="outline" color="gray" radius="xl" sx={{color: '#5b5959'}}
                    to="/engagieren/lehrer"
                    component={Link}
                >
                    Als Sprachhelfer*in anmelden
                </Button>
     
            </Group>
  
            <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
          </Group>

          
        </Header>

        <Drawer
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="md"
          title="Navigation"
          className={classes.hiddenDesktop}
          zIndex={1000000}
        >
          <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
            
            <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />
  
            {pages.map((page) => {
              return  <Text onClick={closeDrawer} key={page.title} to={page.link} component={Link} className={classes.link}>
                        {page.title}
                      </Text>
            })}
  
            <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />
  
            

            <Flex direction='column' justify='space-between' gap="10px" p="10px">
              <Button
                   variant="outline" color="gray" radius="xl" sx={{color: '#5b5959'}}
                    to="/engagieren/lehrer"
                    component={Link}
                    
                >
                    Als Sprachhelfer*in anmelden
                </Button>
            </Flex>
          </ScrollArea>
        </Drawer>
      </Box>
    );
  }

export default Navigation
