import React from 'react';
import { createStyles, Box, Text, Group, rem } from '@mantine/core';
import { ListSearch } from 'tabler-icons-react'
import { Link } from 'react-router-dom';



interface News {
    id: number;
    title: string;
    image: string;
    date: string;
    shortText: string;
    longText: string;
  }

const useStyles = createStyles((theme) => ({
  link: {
    ...theme.fn.focusStyles(),
    display: 'block',
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    lineHeight: 1.2,
    fontSize: theme.fontSizes.sm,
    padding: theme.spacing.xs,
    borderTopRightRadius: theme.radius.sm,
    borderBottomRightRadius: theme.radius.sm,
    borderLeft: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  linkActive: {
    fontWeight: 500,
    borderLeftColor: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 6 : 7],
    color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 2 : 7],

    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors[theme.primaryColor][9], 0.25)
          : theme.colors[theme.primaryColor][0],
    },
  },
}));

interface NewsNavigationProps {
  news: News[];
  activeNewsId: string;
}

export function NewsNavigation({ news, activeNewsId }: NewsNavigationProps) {
  const { classes, cx } = useStyles();
  const items = news.map((news) => (
    <Link to={`/veranstaltungen/${news.id}`} style={{ textDecoration: 'none' }}>
        <Box
        key={news.id}
        className={cx(classes.link, { [classes.linkActive]: activeNewsId === news.id.toString() })}
        >
        {news.title}
        </Box>
    </Link>
  ));

  return (
    <div>
      <Group mb="md">
        <ListSearch size="1.1rem" />
        <Text>Veranstaltungen</Text>
      </Group>
      {items}
    </div>
  );
}