import React, { useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';

import './App.css';
import Navigation from './Components/Navigation';
import { Footer } from './Components/Footer';

import AllRoutes from './AllRoutes';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navigation />
        <AllRoutes />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;