import React from 'react';
import { Container, Flex, Grid } from '@mantine/core';
import { UserInfo } from '../Components/UserInfo';
import TitleText from '../Components/TitleText';


// Sample team data
const teamData = [
  {
    avatar: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png',
    name: 'Isla Karademir – Projektleiterin',
    title: 'Öffentlichkeitsarbeit und Koordination',
    phone: '0174 3462012',
    email: 'Isla.karademir@studentisches-forum.de',
  },
  {
    avatar: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png',
    name: 'Assiel Hamdan - Projektleiter ',
    title: 'Netzwerkkoordination',
    phone: '0177 8348265',
    email: 'Assiel.hamdan@studentisches-forum.de',
  },
  {
    avatar: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png',
    name: 'Amelie Kautz - Projektmentorin',
    title: 'Psychologische Betreuung und Coaching',
    phone: '0176 78926716',
    email: 'Amelie.kautz@studentisches-forum.de',
  },
  {
    avatar: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png',
    name: 'Furat Hamdan - Technischer Projektleiter',
    title: 'Digitalcreator',
    phone: '/',
    email: 'Furat.hamdan@studentisches-forum.de',
  },  
  {
    avatar: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png',
    name: 'Issak Aksüngar - Technischer Assistent',
    title: 'Digitalcreator',
    phone: '/',
    email: 'Issak.aksüngar@studentisches-forum.de',
  },
];

export function Team() {
  return (
    <Container  py="xl">
    <TitleText title="Das sind wir" subtitle="Hier findest du unsere Kontaktdaten" />

        <Grid py={20} >
        {teamData.map((member, index) => (
            <Grid.Col xs={12} sm={6} lg={6}>
                <UserInfo
                key={index}
                avatar={member.avatar}
                name={member.name}
                title={member.title}
                phone={member.phone}
                email={member.email}
                />
            </Grid.Col>
        ))}
        </Grid>
    </Container>
  );
}
