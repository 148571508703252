import React from 'react';
import {
    createStyles,
    Image,
    Container,
    Title,
    Button,
    Group,
    Text,
    List,
    ThemeIcon,
    rem,
  } from '@mantine/core';
  import { Check } from 'tabler-icons-react';
import { Link } from 'react-router-dom';

  
  const useStyles = createStyles((theme) => ({
    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },
  
    content: {
      maxWidth: rem(580),
      marginRight: `calc(${theme.spacing.xl} )`,
  
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        marginRight: 0,
      },
    },
  
    title: {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      fontFamily: `Nunito, ${theme.fontFamily}`,
      fontSize: rem(34),
      lineHeight: 1.2,
      fontWeight: 800,
  
      [theme.fn.smallerThan('xs')]: {
        fontSize: rem(28),
      },
    },
  
    control: {
      backgroundColor: '#726B9B',

      "&:hover": {
        backgroundColor: "#8780af",
      }

    },
  
    image: {
      flex: 1,
  
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },
  
    highlight: {
      
      fontSize: rem(44),

      
    },
  }));
  
  export function Header() {
    const { theme, classes } = useStyles();
    return (
      <div>
        <Container>
          <div className={classes.inner}>
            <div className={classes.content}>
              <Title className={classes.title}>
              <span className={classes.highlight}> A</span>lle  <span className={classes.highlight}>L</span>ernen <span className={classes.highlight}>E</span>ngagieren <span className={classes.highlight}>F</span>ördern
              </Title>
              <Text color="dimmed" mt="md">
       
ALEF ist eine Initiative des studentischen Forums für Partizipation. 
Wir bieten berlinweit von Student*innen geleitete Deutschkurse für unbegleitete Minderjährige und Erwachsene Menschen mit Fluchterfahrung an. 

              </Text>
  
              <List
                mt={30}
                spacing="sm"
                size="sm"
                icon={
                  <ThemeIcon size={20} radius="xl" color={theme.colors.dark[3]}>
                    <Check size={rem(12)}  strokeWidth="3" />
                  </ThemeIcon>
                }
              >
                Student*innen, die sich bei ALEF engagieren profitieren von: <br/> <br/>
                <List.Item>
                  <b> Praktikumsanrechnung gemäß Tätigkeiten in der Geflüchtetenhilfe</b>  <br/> Erwerbe bis zu 15 Leistungspunkte 
                </List.Item>
                <List.Item>
                  <b> Regelmäßige Briefings, Seminare und Veranstaltungen</b>  <br/> Gewährleisten einerseits einen reibungslosen und qualitativ hochwertigen Unterricht und anderseits fördern sie den gemeinsamen Austausch über die gesammelten Erfahrungen
                </List.Item>
                <List.Item>
                  <b>Sei Teil eines starken studentischen Netzwerkes</b>  <br/> ALEF ist nur der Anfang. Es sind weitere Projekte geplant, die darauf abzielen, gesellschaftliche Missstände zu bekämpfen. 
Du interessierst dich für gesellschaftliche Themen und hast vielleicht sogar eine Projekt Idee? Wir unterstützen dich bei der Umsetzung!
                </List.Item>
              </List>
  
             <Group mt={30}>
             
                <Button
                   radius="xl" size="md" 
                   variant="filled"
                   className={classes.control}
                    to="/engagieren"
                    component={Link}
                >
                    Jetzt mitmachen
                </Button>
                <Button variant="subtle" radius="xl" color="gray.0" style={{color:"black"}} size="md"  to="/about" component={Link}>
                 Mehr erfahren
                </Button>
              </Group>
            </div>
            <Image src={process.env.PUBLIC_URL + "Alefimg.svg"} className={classes.image} />
          </div>
        </Container>
      </div>
    );
  }