import React from 'react'
import { ThemeIcon, Text, Timeline, Box, rem, createStyles, Card, Title } from '@mantine/core';


const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(34),
        fontWeight: 900,
    
        [theme.fn.smallerThan('sm')]: {
          fontSize: rem(24),
        },
      },
    
      description: {
        maxWidth: 600,
        margin: 'auto',
    
        '&::after': {
          content: '""',
          display: 'block',
          backgroundColor: '#726B9B',
          width: rem(45),
          height: rem(2),
          marginTop: theme.spacing.sm,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
  }));

export default function Anleitung() {

    const { classes, theme } = useStyles();

    const data = [{
        title: "Motivation",
        text: "Du unterstützt unsere Ziele und möchtest Teil des Netzwerks werden."
    },
    {
        title: "Anmeldung",
        text: "Melde dich als Sprachhelfer*in an und wähle deine Präferenz nach Zeit und Standort."
    },
    {
      title: "Virtuelles Meeting",
      text: "Treffe dich online zu einem kurzen Kennenlernen mit uns. Hier gibt es nochmal Raum für offene Fragen."
    },    
    {
        title: "Du erhältst einen Vertrag",
        text: "Diesen kannst du später an deiner Universität zum anrechnen von Leistungspunkten im Rahmen eines Praktikums einreichen."
    },
    {
        title: "Einführung",
        text: "Wir arbeiten dich ein, dabei arbeiten wir eng mit professionellen Coachs zusammen, die einen reibungslosen Einstieg in die Deutschkurse ermöglichen."
    },
    {
        title: "Willkommen im Team!",
        text: "Nun bist du ein Teil des Projektes, engagierst dich gesellschaftlich und förderst Menschen mit Fluchterfahrungen beim erlernen der deutschen Sprache!"
    }]


  return (
    
    <Box>
      <Timeline>
        {data.map((item, index) => {
            return <Timeline.Item
            title={item.title}
             bulletSize={34}
            bullet={
              <ThemeIcon
                size={34}
                variant="gradient"
                gradient={{ from: '#4e467d', to: '#8a81c1' }}
                radius="xl"
              >
               <Text fw="bold">{index + 1}</Text>
              </ThemeIcon>
            }
          >
            <Text  size="sm">
              {item.text}
            </Text>
          </Timeline.Item>
        })}
        
      </Timeline>
    </Box>
  );
}


