import React, { useState, useEffect } from 'react';
import { createStyles,Paper } from '@mantine/core';

const cooperationPartners: string[] = [
    //`${process.env.PUBLIC_URL}/partner/handelsverband.svg`,
    //`${process.env.PUBLIC_URL}/partner/blfpb.svg`,
    `${process.env.PUBLIC_URL}/partner/Marbuger.svg`,
    `${process.env.PUBLIC_URL}/partner/VOEBB-Logo-Footer.svg`,
    `${process.env.PUBLIC_URL}/partner/sozidia.png`,
    `${process.env.PUBLIC_URL}/partner/umgebung.png`
  ];
  

  const useStyles = createStyles((theme) => ({
    brand: {
      margin: '0 5px', 
      maxWidth: "80px",
      filter: 'grayscale(100%) brightness(90%)',
      maxHeight: "100px",
  
      [theme.fn.largerThan('sm')]: {
        margin: '0 20px', 
        height: 'fit-content', 
        maxWidth: "250px",
      },
    },
  }));

const Partner = () => {
  const [partners, setPartners] = useState<string[]>([]);
  const { classes, theme } = useStyles();

  useEffect(() => {
    setPartners(cooperationPartners);
  }, []);

  return (
    <section style={{ backgroundColor: 'rgb(248, 249, 250)', padding: '0px 0px 30px 0px', overflow: 'hidden' }}>
      <Paper shadow="sm" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'transparent', boxShadow: 'none' }}>
        {partners.map((partner: string, index: number) => (
          <img
            className={classes.brand}
            key={index}
            src={partner}
            alt={`Cooperation partner ${index + 1}`}
            
          />
        ))}
      </Paper>
    </section>
  );
};

export default Partner;
