import { Text, Title,createStyles,rem } from '@mantine/core'
import React from 'react';

interface TitleProps{
    title: string;
    subtitle: string;
}

 
  const useStyles = createStyles((theme) => ({
    title: {
      fontSize: rem(34),
      fontWeight: 700,
  
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(24),
      },
    },
  
    description: {
      maxWidth: 600,
      margin: 'auto',
  
      '&::after': {
        content: '""',
        display: 'block',
        backgroundColor: '#726B9B',
        width: rem(45),
        height: rem(2),
        marginTop: theme.spacing.sm,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }
  }));

export default function TitleText({title, subtitle}: TitleProps) {
const { classes, theme } = useStyles();

  return (
   <>
        <Title order={2} className={classes.title} ta="center" mt="sm">
         {title}
        </Title>
  
        <Text c="dimmed" className={classes.description} ta="center" mt="md">
        {subtitle}
        </Text>
   </>
  )
}
