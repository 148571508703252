import React from 'react'


    import { createStyles, Card, Image, Text, Group, rem, Divider, Stack } from '@mantine/core';

    const useStyles = createStyles((theme) => ({
      card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
      },
        
      title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        lineHeight: 1,
      },
    }));
    
    interface KursInfo {
      zeit: string;
      beschreibung: string;
      kapa: number;
    }
    
    interface StandortInfo {
      ort: string;
      adresse: string;
      kurse: KursInfo[];
    }
    
    interface Standort {
      id: string;
      bezirk: string;
      img: string;
      text: string;
      standortInfo: StandortInfo[];
    }


    
    interface StandortInfoProps {
      bezirk: string;
      infos: Standort[]
    }
    
    export function StandortInfo({ bezirk, infos}: StandortInfoProps) {
      const { classes } = useStyles();

      
      return (
        <Card withBorder padding="lg" className={classes.card}>
          <Card.Section>
            <Image src="https://www.unterwegs-in-spandau.de/gallery/wp-content/ngg_featured/stadtbibliothek-spandau-5231.jpg" alt="" height={100} />
          </Card.Section>
    
          <Group position="apart" mt="xl">
            <Text fz="lg" fw={700} className={classes.title}>
              {infos.find(info => info.id === bezirk)!.bezirk}
            </Text>
          </Group>

          <Group spacing={5}>
         
          {   infos.find(info => info.id === bezirk)!.standortInfo.length === 0  ? <Text fz="sm">In diesem Bezirk werden aktuell keine Kurse angeboten</Text>:  infos.find(info => info.id === bezirk)!.standortInfo.map(ort => {
            return <>
                    
                    {ort.kurse.map(kursinfo => {
                      return <Stack>
                        <Text fz="sm">
                        <b>Standort: </b> {ort.ort}<br/>
                        <b>Adresse:</b> <a href={"http://maps.google.com/?q=" + ort.adresse} target="_blank" rel="noopener noreferrer">{ort.adresse}</a><br/>
                        <b>Kurs:</b>  {kursinfo.beschreibung} <br/>
                        <b>Zeit: </b>{kursinfo.zeit}<br/>
                        <b>Frei Plätze:</b> <a style={{color: kursinfo.kapa > 0 ? "green" : "red"}}>{kursinfo.kapa}</a>
                        </Text>

                        <Divider></Divider>
                        
                        
                      </Stack>
                    })}
                    
                  </>
          })}
          
          </Group>
         
        </Card>
      );
    }