import React from 'react';
import {
    createStyles,
    rem,
    Title,
    Text,
    Card,
    SimpleGrid,
    Button,
    Grid
  } from '@mantine/core';
import { Link } from 'react-router-dom';
  
 
  
  interface MarkdownObject {
    title: string;
    description: string | React.ReactElement;
    button?: {
        text: string;
        onClick: () => void;
        color?: string;
        size?: string;
        variant?: string;
        bg?:string;
        radius?:string;
        link:string;
      };
      image?: React.ReactElement;
  }

  interface Props {
    data: MarkdownObject[]
  }


  
  const useStyles = createStyles((theme) => ({
  
  
    card: {
      border: `${rem(1)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
      }`,
    },
  
    cardTitle: {
      '&::after': {
        content: '""',
        display: 'block',
        backgroundColor: '#726B9B',
        width: rem(45),
        height: rem(2),
        marginTop: theme.spacing.sm,
      },
    },
  }));
  
  export function Participate({data} : Props): JSX.Element{
    const { classes, theme } = useStyles();

    
    const features = data.map((feature : MarkdownObject) => (
      <Grid align="flex-start">
      <Card key={feature.title}  radius="md" className={classes.card} padding="xl">
    

            <Grid.Col>
                {/** } <feature.icon size={rem(50)} stroke={2} color="#726B9B" /> {**/}
                <Text fz="lg" fw={700} className={classes.cardTitle} mt="md" mb="md">
                {feature.title}
                </Text>

                  {feature.description}
                 
            </Grid.Col>

            <Grid.Col display="flex">
              {feature.button? 
                <Button
                    color={feature.button.color}
                    size={feature.button.size}
                    variant={feature.button.variant}
                    onClick={feature.button.onClick}
                    bg={feature.button.bg}
                    radius={feature.button.radius}
                    to={feature.button.link}
                    component={Link}
                >
                    {feature.button.text}
                </Button>
                : <></>}

            {feature.image}
              
            </Grid.Col>
        
      </Card>
      </Grid>
    ));
  
    return (
      <>
  
        <SimpleGrid cols={2} spacing={40} mt={40} mb={60} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
          {features}
        </SimpleGrid>
      </>
    );
  }