import React from 'react';

import { Link } from 'react-router-dom';
import { createStyles, Card, Image, Text, AspectRatio } from '@mantine/core';

interface News {
    id: number;
    title: string;
    image: string;
    date: string;
    shortText: string;
    longText: string;
  }


interface NewsCardProps {
  news: News;
}

const useStyles = createStyles((theme) => ({
  card: {
    transition: 'transform 150ms ease, box-shadow 150ms ease',

    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: theme.shadows.md,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 600,
  },
}));

const NewsCard: React.FC<NewsCardProps> = ({ news }) => {
  const { classes } = useStyles();

  return (
    <Link to={`/veranstaltungen/${news.id}`} style={{ textDecoration: 'none' }}>
      <Card p="md" radius="md" component="a" href="#" className={classes.card}>
        <AspectRatio ratio={1920 / 1080}>
          <Image src={news.image} />
        </AspectRatio>
        <Text color="dimmed" size="xs" transform="uppercase" weight={700} mt="md">
          {news.date}
        </Text>
        <Text className={classes.title} mt={5}>
          {news.title}
        </Text>
      </Card>
    </Link>
  );
};

export default NewsCard;
