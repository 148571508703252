import React, { useState } from 'react'
import Map from '../Components/Map/Map'
import { Container, Grid, createStyles,rem } from '@mantine/core'
import { StandortInfo } from '../Components/Map/StandortInfo'
import TitleText from '../Components/TitleText';


const useStyles = createStyles((theme) => ({
  toptitle: {
      fontSize: rem(34),
      fontWeight: 900,
  
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(24),
      },
    },
  
    topdescription: {
      maxWidth: 600,
      margin: 'auto',
  
      '&::after': {
        content: '""',
        display: 'block',
        backgroundColor: '#726B9B',
        width: rem(45),
        height: rem(2),
        marginTop: theme.spacing.sm,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
}));

interface KursInfo {
  zeit: string;
  beschreibung: string;
  kapa: number;
}

interface StandortInfo {
  ort: string;
  adresse: string;
  kurse: KursInfo[];
}

interface Standort {
  id: string;
  bezirk: string;
  img: string;
  text: string;
  standortInfo: StandortInfo[];
}

export default function Standorte() {
    const [current, setCurrent] = useState("pankow")

    const infos : Standort[] = [
      {
        "id": "tempelhof-schoeneberg",
        "bezirk": "Tempelhof-Schöneberg",
        "img": "",
        "text": "",
        "standortInfo": []
      },
      {
        "id": "charlottenburg-wilmersdorf",
        "bezirk": "Charlottenburg-Wilmersdorf",
        "img": "",
        "text": "",
        "standortInfo": []
      },
      {
        "id": "friedrichshain-kreuzberg",
        "bezirk": "Friedrichshain-Kreuzberg",
        "img": "",
        "text": "",
        "standortInfo": [{
          "ort": "Müllenhoffstraße",
          "adresse": "Müllenhoff Straße, 10967 ",
          "kurse": [
            {
              "zeit": "/",
              "beschreibung": "Deutsch als Fremdsprache für minderjährige Geflüchtete",
              "kapa": 0
            }
          ]
        }]
      },
      {
        "id": "lichtenberg",
        "bezirk": "Lichtenberg",
        "img": "",
        "text": "",
        "standortInfo": []
      },
      {
        "id": "marzahn-hellersdorf",
        "bezirk": "Marzahn-Hellersdorf",
        "img": "",
        "text": "",
        "standortInfo": []
      },
      {
        "id": "mitte",
        "bezirk": "Mitte",
        "img": "",
        "text": "",
        "standortInfo": [{
          "ort": "Straßburgerstraße",
          "adresse": "Straßburger Straße ",
          "kurse": [
            {
              "zeit": "/",
              "beschreibung": "Deutsch als Fremdsprache für minderjährige Geflüchtete",
              "kapa": 0
            }
          ]
        },
        {
          "ort": "Rathenower Straße",
          "adresse": "Rathenower Straße, 10559",
          "kurse": [
            {
              "zeit": "/",
              "beschreibung": "Deutsch als Fremdsprache für minderjährige Geflüchtete",
              "kapa": 0
            }
          ]
        }]
      },
      {
        "id": "neukoelln",
        "bezirk": "Neukölln",
        "img": "",
        "text": "",
        "standortInfo": []
      },
      {
        "id": "pankow",
        "bezirk": "Pankow",
        "img": "",
        "text": "Wir bieten in der Heinrich-Böll-Bibliothek Deutschkurse und Alphabetisierungskurse an",
        "standortInfo": [
          {
            "ort": "Heinrich Böll Bibliothek",
            "adresse": "Greifswalder Straße 87 10409 Berlin",
            "kurse": [
              {
                "zeit": "Freitag 13:00-14:30 Uhr",
                "beschreibung": "Deutsch als Fremdsprache für minderjährige Geflüchtete",
                "kapa": 15
              },
              {
                "zeit": "Freitag 15:30-17:00 Uhr",
                "beschreibung": "Alphabetisierungskurs für minderjährige Geflüchtete",
                "kapa": 15
              },
              {
                "zeit": "Samstag 13:00-14:30 Uhr",
                "beschreibung": "Deutsch als Fremdsprache für erwachsene Geflüchtete (ab 18 Jahre)",
                "kapa": 15
              }
            ]
          }
        ]
      },
      {
        "id": "reinickendorf",
        "bezirk": "Reinickendorf",
        "img": "",
        "text": "",
        "standortInfo": []
      },
      {
        "id": "spandau",
        "bezirk": "Spandau",
        "img": "",
        "text": "",
        "standortInfo": []
      },
      {
        "id": "steglitz-zehlendorf",
        "bezirk": "Steglitz-Zehlendorf",
        "img": "",
        "text": "",
        "standortInfo": []
      },
      {
        "id": "treptow-koepenick",
        "bezirk": "Treptow-Köpenick",
        "img": "",
        "text": "",
        "standortInfo": [{
          "ort": "Mahlsdorferstraße",
          "adresse": "Mahlsdorferstraße, 12555 Berlin ",
          "kurse": [
            {
              "zeit": "/",
              "beschreibung": "Deutsch als Fremdsprache für minderjährige Geflüchtete",
              "kapa": 0
            }
          ]
        }]
      }
    ] 

  return (
    <Container mt="40px">
        <TitleText title="Standorte" subtitle=" Hier findest du alle Standorte, an denen unterrichtet wird und die verfügbaren Plätze." />

        <Grid mt="30px">
            <Grid.Col span={12} sm={8}> <Map setCurrent={setCurrent} infos={infos}/></Grid.Col>
            <Grid.Col span={12} sm={4}> <StandortInfo bezirk={current} infos={infos} /></Grid.Col>
        </Grid>
    </Container>
  )
}
