import React from 'react';
import { useParams } from 'react-router-dom';
import { createStyles, Card, Image, Text, Group, RingProgress, rem, Container, Grid, Col } from '@mantine/core';
import { NewsNavigation } from './NewsNavigation';

interface News {
    id: number;
    title: string;
    image: string;
    date: string;
    shortText: string;
    longText: string;
  }

interface NewsDetailsProps {
  news: News[];
}

const newsData: News[] = [
    {
      id: 1,
      title: 'Veranstaltung 1',
      image: 'https://assets.deutschlandfunk.de/FILE_a1acdee19bc24126b5ea86eb8ae024d7/1920x1080.jpg?t=1597591420827',
      date: '2023-05-01',
      shortText: 'Kurztext 1',
      longText: 'Langertext 1',
    },
    {
        id: 2,
        title: 'Veranstaltung 2',
        image: 'https://www.fu-berlin.de/sites/startseite-test/inhalte/intl-menschen.jpg?width=1300',
        date: '2023-05-01',
        shortText: 'Kurztext 1',
        longText: 'Langertext 1',
      },
  
  ];

  const useStyles = createStyles((theme) => ({
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
  
    title: {
      lineHeight: 1,
    },
  }));

const NewsDetails: React.FC = () => {
  const { classes } = useStyles();
  const { newsId } = useParams<{ [key: string]: string }>();
  const news = newsId ? newsData.find((e) => e.id === parseInt(newsId, 10)) : undefined;

  if (!news) {
    return <Text align="center">News not found</Text>;
  }

  return (
    <Container size="md" py="xl">

      <Grid>
        <Col span={3}>
          <NewsNavigation news={newsData} activeNewsId={news.id.toString()} />
        </Col>
        <Col span={9}>
        <Card withBorder padding="lg" className={classes.card}>
      <Card.Section>
        <Image src={news.image} alt={news.title} height={300} />
      </Card.Section>

      <Group position="apart" mt="xl">
        <Text fz="xl" fw={700} className={classes.title}>
          {news.title}
        </Text>
        <Group spacing={5}>
          <Text fz="md" c="dimmed">
          Am: {news.date}
          </Text>
          <RingProgress size={18} sections={[{ value: 80, color: 'blue' }]} />
        </Group>
      </Group>
      <Text mt="sm" mb="md" c="dimmed" fz="md">
      txt
      </Text>
      
    </Card>
        </Col>
      </Grid>

      

    </Container>
  );
};

export default NewsDetails;