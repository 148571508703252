import React, { useEffect } from 'react'

interface KursInfo {
	zeit: string;
	beschreibung: string;
	kapa: number;
  }
  
  interface StandortInfo {
	ort: string;
	adresse: string;
	kurse: KursInfo[];
  }
  
  interface Standort {
	id: string;
	bezirk: string;
	img: string;
	text: string;
	standortInfo: StandortInfo[];
  }

  
interface MapProps {
    setCurrent: (id: string) => void;
	infos: Standort[]
  }
  
  export default function Map({ setCurrent, infos }: MapProps) {

	

    useEffect(() => {
   

			infos.forEach(ort => {
				if(ort.standortInfo.length > 0){
					document.getElementById(ort.id)!.classList.add('st0')
				} else{
					document.getElementById(ort.id)!.classList.add('st1')
				}
				
				//ort.standortInfo.length > 0 ? document.getElementById(ort.id)?.classList.add('st0') : document.getElementById(ort.id)?.classList.add('st1')
			})

			const clickablePaths = document.querySelectorAll('.st0, .st1');

            clickablePaths.forEach(path => {
                
                path.addEventListener('mouseover', () => {
                    setCurrent(path.getAttribute("id") || "")
                  clickablePaths.forEach(p => {
                    p.classList.remove('clicked');
                  });
                  path.classList.add('clicked');
                });
              });
        
            
    }, [])

  return (
    
    <div >


        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3720 3065" preserveAspectRatio="xMinYMin">

		<defs>
			<pattern id='a' patternUnits='userSpaceOnUse' width='30' height='20' patternTransform='scale(2) rotate(145)'>
			<rect x='0' y='0' width='100%' height='100%' fill='hsla(0,0%,100%,1)'/>
			<path d='M0 10h20z'  transform='translate(5,0)' stroke-width='0.5' stroke='hsla(258.5,59.4%,59.4%,1)' fill='none'/>
			</pattern>
			</defs>
					<path id="tempelhof-schoeneberg" fill="url(#a)"  d="M1408.3,1557.3l137.9,50.3c3.2,1.2,5.3,4.2,5.3,7.5v33.2c0,2.8,1.5,5.4,3.9,6.9
			l30.4,18c3.6,2.1,4.9,6.7,3.1,10.5l-14.4,28.5c-0.7,1.4-1,2.9-0.8,4.5l0.9,8.7c0.4,4,3.8,7.1,7.8,7.2l56.3,1.3c2,0,4-0.7,5.5-2l8-7
			c1.5-1.3,3.5-2,5.5-2l25.9,0.6c3.1,0.1,5.9,2,7.2,4.8l3.8,8.9c1.3,3.1,4.5,5.1,7.9,4.8l21.7-1.4c0.9-0.1,1.9-0.3,2.7-0.7l15.3-6.8
			c5-2.2,10.7,1.1,11.2,6.5l3.6,37.7c0.4,3.9,3.6,7,7.5,7.2l27.3,1.6c3.6,0.2,6.6,2.8,7.3,6.3l4.8,22.7c0.1,0.4,0.1,0.7,0.2,1.1
			l3.3,44.3l4.4,39.4c0.5,4.3,4.4,7.5,8.7,7.1l15-1.4c1.7-0.2,3.3,0.2,4.8,1l2.5,1.4c2.7,1.6,4.2,4.6,4,7.7l-3.8,41.1
			c-0.3,3.1,1.2,6.1,3.9,7.7l15.1,8.8c3.8,2.2,5.1,7,2.9,10.9l-2.6,4.6c-1.2,2.1-3.3,3.6-5.7,4l-16.5,2.6c-2.7,0.4-5,2.2-6.1,4.6
			l-32.5,72.2c-0.1,0.3-0.3,0.7-0.4,1l-16.5,55.8c-0.1,0.2-0.1,0.4-0.1,0.5l-11.4,51.5l-20.4,75.5c-0.1,0.5-0.3,0.9-0.5,1.3l-12.3,26
			c-2.3,4.9,0.8,10.6,6.1,11.3l1.6,0.2c4.2,0.6,7.1,4.3,6.8,8.5l-0.9,11.6c-0.3,3.5-2.8,6.4-6.2,7.2l-19.5,4.4
			c-4.5,1-7.2,5.7-5.9,10.1l12.3,40.9c1.1,3.7,4.8,6.1,8.7,5.6l31-4c1.9-0.3,3.9,0.2,5.5,1.3l42,28.7c1.6,1.1,2.8,2.8,3.3,4.7
			l49.8,203.6c0.4,1.8,0.3,3.6-0.5,5.2l-38.6,83.8c-1.4,3-4.5,4.9-7.8,4.6l-157.7-10.2c-3.9-0.3-7-3.3-7.4-7.2l-8.6-84.1
			c-0.4-4-3.7-7.1-7.7-7.2l-76.8-2.3c-4.6-0.1-8.1-4.1-7.7-8.7l3.4-37.2c0.3-2.8-1-5.6-3.3-7.2l-146.5-105.5
			c-2.1-1.5-3.3-3.9-3.3-6.5v-30.3c0-0.2,0-0.4,0-0.6l-2.6-34.6c-0.2-3.1,1.4-6,4.1-7.6l56.6-31.8l49.4-26.4c1.3-0.7,2.3-1.7,3.1-2.9
			l40.1-66.9c1.2-2,1.5-4.4,0.7-6.6l-44.1-132.2l-37.1-98.8c-0.8-2.1-2.4-3.8-4.5-4.6l-34.3-13.6c-1.4-0.6-2.6-1.5-3.5-2.8
			l-53.5-74.2c-2-2.8-5.7-4-9-2.9l-47.4,15.6c-2.1,0.7-4.3,0.5-6.3-0.5l-33.9-18.1c-2.6-1.4-4.2-4.1-4.2-7.1v-81.8
			c0-4.1,3.1-7.6,7.3-8l73.9-6.9c4.1-0.4,7.3-3.8,7.3-8v-110.6c0-1.9,6.5-50.5,7.4-57.4c0.1-0.6,0.2-1.1,0.4-1.6l3.6-10
			c1.3-3.7-0.2-7.8-3.6-9.7l-1-0.6c-4.5-2.5-5.4-8.6-1.9-12.4l21.1-22.6C1401.9,1557,1405.2,1556.2,1408.3,1557.3z"></path>
					
					<path id="steglitz-zehlendorf" d="M198,2137.3l1.1,5.6c0.5,2.8-0.5,5.6-2.6,7.5l-49.2,43.5c-1.4,1.2-2.3,2.9-2.6,4.8
			l-4.7,31.5c-0.2,1.4-0.8,2.8-1.7,3.9L112,2266c-1.5,1.8-3.8,2.9-6.2,2.9H68.6c-1.9,0-3.7,0.7-5.2,1.9l-54.3,46
			c-1.8,1.5-2.8,3.8-2.8,6.1v48.3c0,1.2,0.3,2.4,0.8,3.5l19.1,39.1c1.2,2.5,3.7,4.2,6.5,4.4l6.9,0.6c4.7,0.4,8.7-3.3,8.7-8v-20.9
			c0-3.5,2.3-6.6,5.7-7.7l7.6-2.3c3-0.9,6.3,0.1,8.4,2.5l15.6,18.4c3.4,4,9.5,3.7,12.6-0.5l9.8-13.5c3.1-4.2,9.3-4.4,12.7-0.4
			l14.8,18.1c2,2.5,2.4,5.9,0.9,8.7l-12.3,24c-1.6,3.1-1,6.8,1.4,9.3l79.5,80.3c2.5,2.5,6.3,3.1,9.4,1.5l33-17.2
			c2.4-1.2,5.2-1.2,7.6,0.1l14.3,7.9c4.4,2.4,5.5,8.2,2.3,12.1l-30,36.4c-1.4,1.8-3.6,2.8-5.8,2.9l-12.8,0.6
			c-2.9,0.1-5.5,1.8-6.8,4.4l-4.1,8.1c-1.7,3.4-0.7,7.6,2.3,10l33.9,25.6c3.2,2.4,7.7,2.1,10.5-0.7l4-4c1.8-1.8,2.7-4.5,2.2-7
			l-3.1-18c-0.5-2.7,0.5-5.5,2.6-7.4l46-40.3c1.7-1.5,4.1-2.2,6.4-1.9l124.1,17c3.4,0.5,6.7-1.3,8.2-4.3l11.2-22.5
			c2.6-5.1-0.9-11.2-6.6-11.6l-50.8-3.3c-4.2-0.3-7.5-3.8-7.5-8v-29.4c0-3.1,1.8-6,4.7-7.3l47.1-21.5c0.2-0.1,0.4-0.2,0.6-0.3
			l150.2-84.2c0.8-0.4,1.6-0.7,2.5-0.9l52.8-9.7c0.5-0.1,0.9-0.2,1.3-0.4l106.8-39.5c0.8-0.3,1.7-0.5,2.6-0.5l105.8-1.7
			c4-0.1,7.5,2.9,8,6.9l18.2,125.6c0.5,3.7,3.5,6.5,7.2,6.8l119.6,10.1c2.4,0.2,4.8-0.7,6.5-2.4l98.1-102.5c1.1-1.1,2.4-1.9,3.9-2.2
			l18-4.4c5-1.2,9.9,2.6,9.9,7.8v16.8c0,1.5,0.4,3,1.2,4.3l69.8,110.7c2.2,3.5,6.7,4.7,10.4,2.9l166-84.7c2.9-1.5,4.6-4.5,4.3-7.7
			l-2.2-29.6c-0.2-3.1,1.4-6,4.1-7.6l56.6-31.8l49.4-26.4c1.3-0.7,2.3-1.7,3.1-2.9l40.1-66.9c1.2-2,1.5-4.4,0.7-6.6l-44.1-132.2
			l-37.1-98.8c-0.8-2.1-2.4-3.8-4.5-4.6l-34.3-13.6c-1.4-0.6-2.6-1.5-3.5-2.8l-53.5-74.2c-2-2.8-5.7-4-9-2.9l-47.4,15.6
			c-2.1,0.7-4.3,0.5-6.3-0.5l-36.3-19.4c-1.2-0.6-2.5-0.9-3.8-0.9h-61.5c-0.7,0-1.4-0.1-2.1-0.3l-102.2-27.4
			c-1.3-0.3-2.6-0.4-3.8-0.1l-82.7,18.8l-69.3,15.1c-0.8,0.2-1.6,0.2-2.4,0.2l-68.2-6.2c-0.7-0.1-1.4-0.2-2.1-0.5l-56.7-21.4
			l-37.7-13.4c-1.3-0.5-2.8-0.6-4.2-0.3l-50,9.8c-0.3,0.1-0.5,0.1-0.8,0.1l-53.2,5c-1,0.1-2,0-2.9-0.3l-31.3-8.9
			c-2-0.6-4.2-0.3-6,0.7l-19.7,10.7c-3.3,1.8-7.4,1.1-9.9-1.8l-11.3-12.9c-1.5-1.7-3.7-2.7-6-2.7h-40c-3.7,0-7,2.6-7.8,6.2l-17,73.8
			c-0.5,2.1-1.8,4-3.7,5.1l-44.1,26.2c-0.3,0.2-0.5,0.3-0.8,0.5l-80.4,61.2l-94.5,76.9c-1,0.9-2.3,1.4-3.6,1.7l-102.6,18.4
			C200.1,2128.7,197.2,2132.9,198,2137.3z"></path>
				
					<path id="spandau" d="M195.1,2121.6l-0.2-1.1c-0.3-1.5-1-2.9-2.1-4l-61.7-65.2c-1.6-1.6-2.3-3.9-2.2-6.1l5.2-67
			c0-0.5,0-1,0-1.5l-7-63.7c-0.2-1.5,0.1-3,0.7-4.3l28.9-60.4c0.2-0.4,0.3-0.8,0.5-1.2l12.3-42.5c0.8-2.7,2.9-4.8,5.6-5.5l40.7-10.9
			c1.6-0.4,3.1-1.4,4.1-2.7L433,1521.8c2.9-3.6,2.2-9-1.7-11.6l-121.9-83.8c-1.7-1.1-3.7-1.6-5.7-1.3l-122,17.4
			c-4.9,0.7-9.2-3.1-9.1-8l1.7-116.3c0-0.9,0.2-1.8,0.5-2.6l34.9-95.8c0.2-0.4,0.3-0.9,0.4-1.4l20.7-119.7c0.7-3.8,4-6.6,7.9-6.6
			h10.7c2.8,0,5.4,1.4,6.8,3.8l12.7,20.7c1.5,2.4,4,3.8,6.8,3.8h37.8c2.7,0,5.2-1.4,6.7-3.7l14.1-21.8c1.2-1.8,1.6-4,1.1-6.2
			L323,1036c-0.4-1.6-0.2-3.3,0.4-4.8l43.1-106.5c0.5-1.1,0.7-2.4,0.6-3.6l-3.3-42.4c-0.1-1.5-0.6-2.9-1.5-4.1l-18-24.4
			c-2.1-2.9-5.8-4-9.2-2.8l-78.2,28.2c-3,1.1-6.3,0.3-8.5-2l-24.8-25.5c-1.7-1.7-2.5-4.2-2.2-6.6l4.1-33c0.5-4,3.9-7,7.9-7h33.9
			c1.3,0,2.6-0.3,3.7-0.9l62.2-32.4c0.6-0.3,1.2-0.7,1.7-1.2l65.5-59.4c1.5-1.3,3.4-2.1,5.4-2.1h18.6c1.4,0,2.7,0.4,3.9,1l43,24.1
			c0.6,0.3,1.2,0.6,1.8,0.7l34.9,9.6c0.5,0.1,0.9,0.3,1.4,0.5l71.6,34.9c0.5,0.3,1.1,0.5,1.7,0.6l41.3,9.5c1.1,0.3,2.1,0.7,3,1.4
			l33.4,25.1c1.2,0.9,2.5,1.4,4,1.6l42.4,4.5c4.2,0.4,7.3,4.1,7.2,8.3l-1,23.3c0,0.4,0,0.9,0.1,1.3l2.6,20.9c0,0.2,0.1,0.5,0.1,0.7
			l6.1,28.1c0.4,2,1.6,3.7,3.2,4.8l24.1,17.1c0.9,0.7,1.7,1.5,2.3,2.5l12.6,21.6c0.6,1,1,2.2,1.1,3.3l2.5,28.6c0,0.3,0.1,0.6,0.1,0.9
			l13,62.4c0.4,1.9,1.5,3.6,3.1,4.8l37.3,27.7l64.8,52.5c0.2,0.2,0.5,0.4,0.7,0.5l37.6,24c1.2,0.7,2.5,1.2,3.9,1.2l78.7,4.3
			c4.2,0.2,7.6,3.7,7.6,8v17.6c0,1.8,0.6,3.5,1.7,4.9l10.1,13c1.4,1.8,1.9,4,1.6,6.2l-5.4,32.4c-0.3,1.7,0,3.4,0.7,4.9l9.7,19.3
			c0.5,0.9,1.1,1.8,1.9,2.5l16.4,14.2c1.1,0.9,2.4,1.6,3.8,1.8l11.5,2.2c3.9,0.7,6.7,4.3,6.5,8.2l-0.8,16.1c-0.1,2.4-1.3,4.7-3.3,6.1
			l-27.3,19.7c-1.1,0.8-2.3,1.3-3.6,1.4l-52.9,7.4c-0.2,0-0.3,0-0.5,0l-76.5,6.3c-1,0.1-1.9,0-2.9-0.3l-17.2-4.9
			c-4.5-1.3-9.1,1.5-10,6.1l-2.9,13.8c-1,4.6-5.7,7.4-10.2,6l-19.9-6.1c-0.8-0.2-1.6-0.4-2.4-0.3l-92.4,1.2c-2.4,0-4.6,1.1-6.1,2.9
			l-13.7,16.6c-1.2,1.5-1.9,3.4-1.8,5.3l1.2,44.1c0,0.2,0,0.4,0,0.6l3.6,33.3c0.2,1.5-0.1,3-0.8,4.4l-17.6,36.2
			c-1.2,2.5-3.7,4.2-6.5,4.5l-18,1.6c-3.3,0.3-6.1,2.6-7,5.9l-13.9,51.3c-0.8,3-3.3,5.2-6.3,5.8l-31.1,5.5c-0.4,0.1-0.7,0.1-1,0.3
			l-33.7,10.8c-0.9,0.3-1.8,0.8-2.6,1.4l-26.7,21.7c-0.9,0.8-1.7,1.7-2.2,2.8l-12.6,26.5c-0.4,0.8-0.6,1.6-0.7,2.4l-4.5,35.5
			c-0.1,0.8-0.1,1.6,0,2.3l4.6,27.5c0.1,0.4,0.2,0.8,0.3,1.2l7.4,22.6c0.2,0.7,0.3,1.4,0.4,2l1.2,22.3c0,0.9,0,1.8-0.3,2.6l-5.8,20.9
			c-0.1,0.5-0.2,1-0.3,1.4l-2.8,31.3c0,0.2,0,0.5,0,0.7v37.1c0,0.6-0.1,1.2-0.2,1.8l-18.3,79.1c-0.5,2.1-1.8,4-3.7,5.1l-44.1,26.2
			c-0.3,0.2-0.5,0.3-0.8,0.5l-80.4,61.2l-94.5,76.9c-1,0.9-2.3,1.4-3.6,1.7l-103,18.7C200.1,2128.7,195.9,2125.9,195.1,2121.6z"></path>
				
					<path id="reinickendorf" d="M1661.7,984.1l1.4-6.2c0.6-2.7-0.1-5.4-2-7.4l-23.8-24.6c-1.4-1.4-3.3-2.3-5.3-2.4
			l-17.2-1.1c-1.9-0.1-3.7-0.9-5.1-2.3l-57.5-56l-103.4-91.3c-4.5-3.9-3.2-11.2,2.3-13.4l6.9-2.8c0.7-0.3,1.5-0.5,2.3-0.6l44.2-3.9
			c2.3-0.2,4.4-1.4,5.8-3.2c7-9.6,29.1-39.8,30.7-41.1c1.8-1.5,26.5-43.3,29.6-48.6c0.2-0.4,0.4-0.8,0.6-1.2l14.5-37.7
			c0.3-0.8,0.5-1.6,0.5-2.5l3.2-63.5c0-0.9-0.1-1.8-0.3-2.7l-9.9-33.3c-0.1-0.4-0.3-0.8-0.5-1.2l-19.7-40.8c-0.3-0.6-0.5-1.3-0.7-2
			l-4.5-24.2c-0.9-4.7-5.7-7.6-10.3-6.2l-64.2,20.1c-1.9,0.6-4,0.5-5.9-0.4l-17.9-8.6c-1.4-0.7-2.9-0.9-4.4-0.7l-67.8,7.7
			c-1.4,0.2-2.7,0-4-0.6l-49.7-20.9c-0.3-0.1-0.7-0.3-1-0.3l-66-17.8c-0.7-0.2-1.5-0.3-2.3-0.3l-62.8,1.6c-4.5,0.1-8.2-3.5-8.2-8
			v-1.6c0-2.5,1.1-4.8,3.1-6.3l29.6-23.1c2.9-2.2,3.9-6.1,2.4-9.4l-13.8-32.6c-0.8-1.8-0.8-3.9-0.2-5.8l16.2-46
			c0.7-2,0.6-4.2-0.3-6.1l-40.7-84.8c-1.5-3.2-0.8-7,1.8-9.4l37.1-33.6c3.3-3,3.5-8,0.6-11.3l-10.5-11.5c-1.5-1.7-3.7-2.6-5.9-2.6
			h-64.5c-0.3,0-0.7,0-1-0.1l-60.9-8c-4.4-0.6-8.5,2.6-9,7l-6.6,57.5c-0.4,3.8,1.9,7.4,5.6,8.6l11.1,3.4c3.7,1.1,6,4.7,5.6,8.6
			l-9.6,83.9c-0.4,3.3-2.7,6-5.9,6.8l-47.8,12.8c-1.1,0.3-2.3,0.4-3.5,0.1l-50.8-9c-2.6-0.5-5.2,0.4-7.1,2.2l-4.2,4.2
			c-3.1,3.1-3.1,8.2,0,11.3l16.4,16.4c1.1,1.1,1.9,2.6,2.2,4.2l11.1,57.8c0.2,1.2,0.2,2.5-0.2,3.7l-6.3,22c-1.3,4.4-6,6.9-10.4,5.3
			l-19-6.7c-0.9-0.3-1.8-0.5-2.7-0.5H902c-0.4,0-0.8,0-1.2-0.1l-46.1-7c-1.2-0.2-2.4-0.1-3.5,0.3l-52.2,16c-2.1,0.6-4.4,0.4-6.3-0.7
			l-37.4-21.5c-1.7-1-3.6-1.3-5.5-0.9l-10.3,2c-3.5,0.7-6.2,3.6-6.5,7.2l-3.9,45.1c-0.1,1.2-0.5,2.4-1.1,3.4l-35.5,59.5
			c-0.1,0.1-0.1,0.2-0.2,0.4l-26.3,50c-0.6,1.2-1.5,2.2-2.6,2.9l-32.9,22.2c-2.8,1.9-4.1,5.4-3.2,8.7l6.3,23c0.3,1.1,0.8,2,1.5,2.9
			l38.7,48.3c0.3,0.4,0.7,0.8,1.1,1.2l45.4,37.7c1.5,1.3,2.5,3,2.8,5l5.6,38.4c0.2,1.6,0,3.2-0.7,4.6l-12.8,26.5
			c-0.5,1-0.7,2.1-0.8,3.1l-1.3,29.2c0,0.4,0,0.9,0.1,1.3l2.6,20.9c0,0.2,0.1,0.5,0.1,0.7l6.1,28.1c0.4,2,1.6,3.7,3.2,4.8l24.1,17.1
			c0.9,0.7,1.7,1.5,2.3,2.5l12.6,21.6c0.6,1,1,2.2,1.1,3.3l2.5,28.6c0,0.3,0.1,0.6,0.1,0.9l13,62.4c0.4,1.9,1.5,3.6,3.1,4.8
			l37.3,27.7l64.8,52.5c0.2,0.2,0.5,0.4,0.7,0.5l37.6,24c1.2,0.7,2.5,1.2,3.9,1.2l86.1,4.7c0.1,0,0.3,0,0.4,0h161
			c2.9,0,5.5-1.5,6.9-4l46.7-81.4c1.1-1.9,2.9-3.3,5-3.8l29.9-7.3c0.3-0.1,0.5-0.1,0.8-0.2l40.5-5.6c2.3-0.3,4.3-1.6,5.6-3.5
			l10.1-15.5c1.4-2.2,3.8-3.5,6.4-3.6l26.3-1.1c1.6-0.1,3.2-0.6,4.5-1.6l17.2-13.1c2.1-1.6,4.9-2.1,7.4-1.2l28.2,9.6l21.4,7.8
			c0.8,0.3,1.6,0.4,2.4,0.5l71.5,2.6c1.7,0.1,3.3,0.7,4.7,1.7l16,12.7c1.2,0.9,2,2.1,2.5,3.5l3.5,9.5c1.9,5.3,8.6,7,12.8,3.3
			l16.6-14.5c1.3-1.1,3-1.8,4.7-2l20.9-1.5c2.8-0.2,5.3-1.9,6.6-4.4l7.8-15.6c0.3-0.6,0.5-1.2,0.6-1.8l6.7-28.9
			c0.8-3.4,3.7-6,7.3-6.2l37.1-2.4C1658,990,1660.9,987.6,1661.7,984.1z"></path>
		
					<path id="pankow" className="st0 clicked" d="M1556.1,458.6l41.5-32.7c1.5-1.2,2.5-2.8,2.9-4.6l7.9-37.3c0.4-1.8,1.4-3.4,2.8-4.6l28.7-23
			c0.7-0.5,1.4-1,2.2-1.3l19.1-7.1c2.7-1,4.6-3.3,5.1-6.1l6.4-36.6c0.1-0.6,0.3-1.1,0.5-1.7l17-41.2c1.2-2.8,3.8-4.7,6.9-4.9l5.6-0.4
			c2-0.1,4,0.5,5.6,1.8l44.2,35.9c1.2,0.9,2.6,1.5,4.1,1.7l45,5.4c3.4,0.4,6.2,2.9,6.9,6.3l6.4,30.5c0.4,2,1.6,3.8,3.4,5l29.1,19.7
			c1.3,0.9,2.9,1.4,4.5,1.4h16.6c1.9,0,3.7-0.6,5.1-1.8l17.2-14.2c1-0.9,2.3-1.4,3.6-1.7l10.9-2.1c3.6-0.7,6.3-3.8,6.5-7.5l1.4-33.1
			c0.1-2.3,1.1-4.4,2.9-5.8l17.2-14.2c0.7-0.6,1.3-1.2,1.7-2l15.5-25.2c0.8-1.3,1.9-2.3,3.2-3l28.9-14.4c1.3-0.6,2.7-0.9,4.1-0.8
			l21.8,1.5c1.6,0.1,3.1,0.7,4.4,1.7l17.2,13.6c1.8,1.4,4,2,6.2,1.6l27.7-4.5c1.7-0.3,3.3-1.1,4.5-2.3l28.8-29.6c0.9-0.9,2-1.6,3.3-2
			l26.4-8.6c3.3-1.1,5.5-4.1,5.5-7.6v-7.5c0-3.9-2.8-7.2-6.6-7.9l-24.9-4.3c-0.3,0-0.5-0.1-0.8-0.2l-34.6-9.5c-1-0.3-2-0.8-2.8-1.4
			l-47-36.6c-0.7-0.5-1.3-1.2-1.8-1.9l-6.3-9.6c-2.2-3.3-1.6-7.6,1.3-10.3l33.6-30.8c1.5-1.3,3.4-2.1,5.4-2.1h27.3
			c1.6,0,3.3-0.5,4.6-1.5l21.4-15.1c0.8-0.5,1.5-1.2,2-2l36.4-52.9c1.8-2.7,5.1-4,8.3-3.3l11.5,2.5c4.7,1,7.5,5.9,5.9,10.5
			l-19.1,54.5c-1.4,4.1,0.6,8.6,4.7,10.1l1.6,0.6c2,0.8,4.1,0.7,6.1-0.1l40.9-17.9c3.6-1.6,7.9-0.3,10,3.1l4.1,6.5
			c0.9,1.5,1.3,3.2,1.2,4.9l-7.7,87.8c-0.2,2.3,0.6,4.6,2.2,6.2l34.1,35.7c0.6,0.6,1.3,1.2,2.1,1.6l57.2,29.9
			c0.7,0.3,1.3,0.8,1.8,1.3l33.5,31.8c2,1.9,4.8,2.6,7.5,2l31.5-7.9c2.7-0.7,5.6,0.1,7.6,2.1l14.1,14.1c1.8,1.8,2.6,4.2,2.3,6.7
			l-15.9,118c-0.3,2.6-1.9,4.8-4.2,6l-66.3,35.2c-2.5,1.3-4.2,4-4.2,6.8l-1.6,54.4c0,1.4-0.5,2.8-1.2,4l-39.1,61.6
			c-0.6,0.9-1,1.9-1.1,3l-5.9,36.5c-0.1,0.8-0.1,1.6,0,2.4l5.1,35.9c0.1,0.8,0.4,1.6,0.7,2.4l33.9,69.8l0,0l-50.9,33.2l-31.7,20.2
			c-0.8,0.5-1.5,1.2-2.1,1.9l-15.3,20.5c-0.2,0.2-0.3,0.4-0.4,0.7l-18.5,30.5c-1.4,2.4-4,3.9-6.8,3.9h-5c-2.1,0-4.2,0.8-5.7,2.3
			l-3.5,3.5c-1.5,1.5-2.3,3.5-2.3,5.7v81.9c0,2.7,1.4,5.3,3.7,6.7l21.4,13.8c0.3,0.2,0.6,0.4,0.9,0.7l15.5,13.6
			c3.3,2.9,3.7,7.9,0.8,11.2l-14.3,16.7c-1.6,1.8-2.2,4.3-1.8,6.6l5.8,31.9c0.5,2.8,2.5,5.1,5.2,6.1l17.5,6.3c0.7,0.2,1.3,0.6,1.9,1
			l16.5,11.8c1.2,0.8,2.1,2,2.7,3.3l11.2,25.7c0.4,1,0.7,2.1,0.7,3.2v34.2c0,4.4-3.6,8-8,8h-13.6c-0.8,0-1.7-0.1-2.4-0.4l-20.9-6.7
			c-2.2-0.7-4.7-0.4-6.7,0.8l-27.4,17c-2.1,1.3-3.4,3.4-3.7,5.8l-1.3,10.4c-0.5,4.3-4.4,7.4-8.7,7l-28.4-2.8c-0.3,0-0.5,0-0.8,0h-20
			c-5.1,0-8.9,4.6-7.8,9.6l7,34.2c0.1,0.6,0.2,1.2,0.2,1.9l-0.8,22.5c-0.1,2-0.8,3.8-2.2,5.2l-10.4,10.9c-2.4,2.5-2.9,6.3-1.2,9.3
			l10.4,19.1c2.3,4.2,0.4,9.4-4,11.2l-21.3,8.9c-2.1,0.9-3.8,2.7-4.5,4.9l-6.2,18.5c-0.6,1.7-1.7,3.2-3.3,4.2l-10.4,6.7l-7,4.7
			c-3.7,2.5-4.7,7.5-2.2,11.1l11.5,16.9c0.9,1.3,2.1,2.3,3.5,2.9l14.9,6.3c0.2,0.1,0.4,0.2,0.7,0.2l22.9,7.3c1.6,0.5,2.9,1.5,3.9,2.8
			l10.4,13.6c2.9,3.8,1.8,9.3-2.3,11.7l-5.7,3.3c-0.9,0.5-1.8,0.8-2.8,1l-28.6,4.3c-0.8,0.1-1.6,0.1-2.5,0l-20.7-3.3
			c-1.2-0.2-2.3-0.7-3.3-1.3l-15-10.5c-1.6-1.2-2.8-2.9-3.2-4.8l-5.7-26.2c-0.2-0.8-0.5-1.5-0.9-2.2l-8.5-15.1
			c-1.9-3.3-5.9-4.9-9.5-3.6l-11.7,4c-3.4,1.2-7.2-0.1-9.2-3.2l-19.1-28.9c-2.5-3.8-7.6-4.7-11.3-2.1l-16.1,11.5
			c-1.2,0.9-2.7,1.4-4.2,1.5l-57.8,3.5c-1.4,0.1-2.8,0.6-4,1.4l-34.1,23.2c-2.5,1.7-5.7,1.8-8.3,0.4l-34.5-18.5
			c-0.2-0.1-0.3-0.2-0.5-0.2l-30.3-13.8c-3.5-1.6-5.4-5.5-4.5-9.2l8.8-35.2c0.3-1.4,0.3-2.8-0.1-4.1l-14-49.8
			c-0.1-0.3-0.2-0.6-0.3-0.9c-2.7-6.5-34.1-80.5-34.1-82.8s-4.4-64-4.7-68.3c0-0.2,0-0.3-0.1-0.5l-5.8-41.8c-0.2-1.7-1-3.3-2.2-4.5
			l-38.4-39.2c-1.9-2-2.7-4.8-2.1-7.4l1.8-7.6c0.6-2.7-0.1-5.4-2-7.4l-23.8-24.6c-1.4-1.4-3.3-2.3-5.3-2.4l-17.2-1.1
			c-1.9-0.1-3.7-0.9-5.1-2.3l-57.5-56l-103.4-91.3c-4.5-3.9-3.2-11.2,2.3-13.4l6.9-2.8c0.7-0.3,1.5-0.5,2.3-0.6l44.2-3.9
			c2.3-0.2,4.4-1.4,5.8-3.2c7-9.6,29.1-39.8,30.7-41.1c1.8-1.5,26.5-43.3,29.6-48.6c0.2-0.4,0.4-0.8,0.6-1.2l14.5-37.7
			c0.3-0.8,0.5-1.6,0.5-2.5l3.2-63.5c0-0.9-0.1-1.8-0.3-2.7l-9.9-33.3c-0.1-0.4-0.3-0.8-0.5-1.2l-19.7-40.8c-0.3-0.6-0.5-1.3-0.7-2
			l-5.2-28.4C1552.6,463.4,1553.7,460.4,1556.1,458.6z"></path>
				
					
			<path id="marzahn-hellersdorf" d="M2646.1,930.4l39.5,1.6c2.7,0.1,5.1,1.5,6.5,3.8l7.7,12.4c1.2,2,3.3,3.4,5.7,3.7
			l16.3,2.3c3.3,0.5,6,3,6.7,6.3l8.8,42.4c0.1,0.3,0.1,0.7,0.1,1.1l2.6,36.4c0.1,1,0.3,2,0.7,2.8l14.2,30c0.5,1,0.7,2.2,0.8,3.3
			l0.8,56.3c0.1,3.5,2.4,6.6,5.8,7.6l257.2,74.7c4.2,1.2,6.7,5.6,5.5,9.8l-7.4,26.5c-0.9,3.1-3.5,5.4-6.8,5.8l-41,4.9
			c-4.3,0.5-7.4,4.3-7,8.5l3.4,46.1c0.3,4.2,3.8,7.4,8,7.4l150-0.8c3.1,0,6,1.8,7.3,4.7l14.3,31.3c1.6,3.5,0.5,7.6-2.6,9.8
			l-79.5,56.7c-0.7,0.5-1.3,1-1.7,1.7l-43.6,57.8c-0.3,0.4-0.6,0.8-0.8,1.2l-34.1,68.3c-0.2,0.4-0.4,0.9-0.5,1.3l-15.2,51.7
			c-0.1,0.3-0.2,0.6-0.2,0.9l-5.2,31c-0.7,3.9,1.7,7.8,5.5,9l13.4,4.2c3.7,1.2,6.1,4.8,5.6,8.7l-2.4,18.7c-0.2,1.9-1.2,3.6-2.6,4.9
			l-46.4,42.1c-0.5,0.5-0.9,1-1.3,1.6l-27.8,42.5c-0.6,0.9-1,1.9-1.2,3l-5,28.6c-0.6,3.7,1.4,7.3,4.8,8.8l8,3.3
			c4.3,1.8,6.2,6.9,4,11.1l-14.9,28.5c-1.3,2.5-3.7,4.1-6.5,4.3l-14.5,1.1c-3.4,0.3-6.6-1.7-7.9-4.8l-4.2-9.7
			c-1.1-2.6-3.5-4.4-6.4-4.8l-15.7-2c-1.1-0.1-2.1-0.5-3-1l-16.7-9.8c-0.4-0.3-0.8-0.5-1.2-0.9l-37.9-33c-0.2-0.2-0.5-0.4-0.8-0.6
			l-31.5-21.3c-1.2-0.8-2.6-1.3-4.1-1.4l-34.7-1.7c-0.9,0-1.9,0.1-2.8,0.4l-15.3,4.9c-2.2,0.7-4,2.3-4.9,4.4l-14.7,33.2
			c-0.8,1.8-2.2,3.2-4,4l-47.5,21.7c-1.2,0.5-2.5,0.8-3.8,0.7l-51.7-3.1c-4.2-0.3-7.5-3.8-7.5-8v-24.2c0-1.9-0.7-3.7-1.9-5.2
			l-12.5-14.7c-0.8-0.9-1.3-2-1.6-3.1l-10.5-39.4l-12.7-53.6c0-0.2-0.1-0.4-0.1-0.6l-8.8-56.3c-0.2-1.2-0.7-2.4-1.4-3.4l-41.9-59.6
			c-2.5-3.6-1.7-8.6,1.9-11.1l2.7-2c2.2-1.6,3.5-4.2,3.3-7l-3.3-56.1c-0.1-1.6-0.7-3.2-1.7-4.5l-9.7-12.3c-1.5-1.9-3.8-3-6.3-3
			h-74.2c-4.3,0-7.9-3.5-8-7.8l-4.2-184c-0.1-3.8,2.5-7.1,6.1-8l42.4-10.2c0.4-0.1,0.8-0.2,1.2-0.4l21.9-9.2c3.4-1.4,5.4-5,4.8-8.7
			l-10.7-64.5c-0.1-0.4-0.2-0.8-0.3-1.1l-15.1-47.2c-0.1-0.4-0.2-0.9-0.3-1.4l-4.9-35.8c-0.3-2.4,0.4-4.8,2.1-6.5l84.1-90.3
			c0.7-0.7,1.4-1.3,2.3-1.7l42.6-21.3l92.4-42.2C2643.6,930.6,2644.8,930.3,2646.1,930.4z"></path>
			
				
					
			<path id="lichtenberg" d="M2639.7,922.6c2.4,4.1,0.8,9.4-3.6,11.3l-86,39.3l-42.6,21.3
			c-0.9,0.4-1.6,1-2.3,1.7l-84.1,90.3c-1.6,1.8-2.4,4.2-2.1,6.5l4.9,35.8c0.1,0.5,0.2,0.9,0.3,1.4l15.1,47.2c0.1,0.4,0.2,0.7,0.3,1.1
			l10.7,64.5c0.6,3.7-1.4,7.3-4.8,8.7l-21.9,9.2c-0.4,0.2-0.8,0.3-1.2,0.4l-42.4,10.2c-3.7,0.9-6.2,4.2-6.1,8l4.2,184
			c0.1,4.3,3.7,7.8,8,7.8h74.2c2.4,0,4.8,1.1,6.3,3l9.7,12.3c1,1.3,1.6,2.9,1.7,4.5l3.3,56.1c0.2,2.7-1.1,5.4-3.3,7l-2.7,2
			c-3.6,2.6-4.4,7.5-1.9,11.1l41.9,59.6c0.7,1,1.2,2.2,1.4,3.4l8.8,56.3c0,0.2,0.1,0.4,0.1,0.6l12.7,53.6l10.5,39.4
			c0.3,1.1,0.9,2.2,1.6,3.1l12.5,14.7c1.2,1.4,1.9,3.3,1.9,5.2v23.8c0,4.4-3.6,8-8,8H2537c-0.3,0-0.6,0-1-0.1l-40.7-4.9
			c-2.8-0.3-5.6,0.8-7.3,3.1l-38.7,50.3c-3.4,4.4-10.1,4.1-13.1-0.6l-15.8-25l-20.1-28.4c-1.2-1.7-3-2.9-5.1-3.3l-35.2-6.6
			c-0.6-0.1-1.2-0.2-1.8-0.1l-41.8,1.5c-3.9,0.1-7.3-2.5-8.1-6.2l-6.4-28.6c-0.2-1-0.6-1.9-1.2-2.7l-16.2-23.9c-1.4-2-3.5-3.3-6-3.5
			l-32.1-2.7c-4.4-0.4-7.6-4.2-7.3-8.6l1-13.4c0.2-2.1-0.5-4.2-1.9-5.8l-21.4-24.7c-0.1-0.1-0.2-0.2-0.3-0.3l-27-27.9
			c-0.2-0.2-0.4-0.4-0.5-0.6l-31.3-39.4c-0.3-0.4-0.6-0.7-1-1l-21.8-19.3c-0.9-0.8-2-1.4-3.2-1.7l-28.1-7.7c-3.8-1.1-6.3-4.8-5.8-8.7
			l2.6-20.6c0.1-1.2,0.6-2.3,1.2-3.3l24.3-37.7c0.2-0.4,0.4-0.7,0.6-1.1l17.1-38.9c0.6-1.3,0.8-2.8,0.6-4.3l-6.7-50.6
			c-0.2-1.3-0.7-2.6-1.5-3.7l-21.6-29.4l-15.8-20.8c-1-1.3-2.4-2.3-3.9-2.8l-22.9-7.3c-0.2-0.1-0.4-0.2-0.7-0.2l-14.9-6.3
			c-1.4-0.6-2.6-1.6-3.5-2.9l-11.5-16.9c-2.5-3.7-1.5-8.7,2.2-11.1l7-4.7l10.4-6.7c1.5-1,2.7-2.5,3.3-4.2l6.2-18.5
			c0.7-2.2,2.4-4,4.5-4.9l21.3-8.9c4.4-1.8,6.2-7,4-11.2l-10.4-19.1c-1.7-3-1.2-6.8,1.2-9.3l10.4-10.9c1.3-1.4,2.1-3.3,2.2-5.2
			l0.8-22.5c0-0.6,0-1.3-0.2-1.9l-7-34.2c-1-5,2.8-9.6,7.8-9.6h20c0.3,0,0.5,0,0.8,0l28.4,2.8c4.3,0.4,8.2-2.7,8.7-7l1.3-10.4
			c0.3-2.4,1.7-4.5,3.7-5.8l27.4-17c2-1.2,4.4-1.5,6.7-0.8l20.9,6.7c0.8,0.3,1.6,0.4,2.4,0.4h13.6c4.4,0,8-3.6,8-8v-34.2
			c0-1.1-0.2-2.2-0.7-3.2l-11.2-25.7c-0.6-1.3-1.5-2.5-2.7-3.3l-16.5-11.8c-0.6-0.4-1.3-0.8-1.9-1l-17.5-6.3c-2.7-1-4.7-3.3-5.2-6.1
			l-5.8-31.9c-0.4-2.4,0.2-4.8,1.8-6.6l14.3-16.7c2.9-3.3,2.5-8.3-0.8-11.2l-15.5-13.6c-0.3-0.3-0.6-0.5-0.9-0.7l-21.4-13.8
			c-2.3-1.5-3.7-4-3.7-6.7v-81.9c0-2.1,0.8-4.2,2.3-5.7l3.5-3.5c1.5-1.5,3.5-2.3,5.7-2.3h5c2.8,0,5.4-1.5,6.8-3.9l18.5-30.5
			c0.1-0.2,0.3-0.4,0.4-0.7l15.3-20.5c0.6-0.8,1.3-1.4,2.1-1.9l31.7-20.2l50.9-33.2l0,0l11.8,24.3c1.3,2.7,4,4.4,7,4.5l89.4,2.6
			c0.6,0,1.1,0.1,1.6,0.2l66.7,16c0.7,0.2,1.4,0.4,2,0.8l87.4,48.1c0.8,0.5,1.6,1,2.2,1.8l41.5,47.7c0.3,0.4,0.6,0.8,0.9,1.2
			L2639.7,922.6z"></path>
			
				
					
			<path id="friedrichshain-kreuzberg" d="M1837.6,1357.8l28.8-19.5c1.2-0.8,2.6-1.3,4-1.4l57.8-3.5
			c1.5-0.1,2.9-0.6,4.2-1.5l16.1-11.5c3.7-2.6,8.8-1.7,11.3,2.1l19.1,28.9c2,3,5.8,4.3,9.2,3.2l11.7-4c3.6-1.2,7.6,0.3,9.5,3.6
			l8.5,15.1c0.4,0.7,0.7,1.4,0.9,2.2l5.7,26.2c0.4,2,1.6,3.7,3.2,4.8l15,10.5c1,0.7,2.1,1.2,3.3,1.3l20.7,3.3c0.8,0.1,1.6,0.1,2.5,0
			l28.6-4.3c1-0.2,2-0.5,2.8-1l7.3-4.3c3.5-2.1,8.1-1.1,10.5,2.2l17.4,23.6c0.8,1.1,1.3,2.3,1.5,3.7l6.7,50.6c0.2,1.5,0,2.9-0.6,4.3
			l-17.1,38.9c-0.2,0.4-0.4,0.8-0.6,1.1l-24.3,37.7c-0.6,1-1.1,2.1-1.2,3.3l-2.6,20.6c-0.5,3.9,2,7.7,5.8,8.7l28.1,7.7
			c1.2,0.3,2.3,0.9,3.2,1.7l21.8,19.3c0.3,0.3,0.7,0.6,1,1l31.3,39.4c0.2,0.2,0.3,0.4,0.5,0.6l18.7,19.3c4,4.1,2.5,11-2.9,13
			l-29.7,11.3c-2.8,1.1-6,0.5-8.2-1.6l-39.5-36.2c-0.5-0.4-1-0.8-1.5-1.1l-97.7-54.5c-2.9-1.6-6.4-1.3-8.9,0.8l-21.6,17.5
			c-2.2,1.7-5.1,2.3-7.7,1.3l-11.2-3.9c-3.3-1.2-7.1,0-9.1,2.9l-27,37.6c-2.4,3.4-7,4.3-10.6,2.2l-83.1-49.5c-3.1-1.8-7-1.4-9.6,1.1
			l-0.3,0.3c-2.4,2.2-3.1,5.7-2,8.7l23.4,60.6c0.9,2.4,0.6,5.1-0.8,7.3l-5.4,8.2c-2,3.1-5.8,4.4-9.3,3.2l-31.7-10.8
			c-0.2-0.1-0.4-0.1-0.6-0.2l-37.6-9.5c-3.4-0.9-7,0.6-8.8,3.6l-13.4,22c-0.8,1.4-2.1,2.5-3.6,3.2l-23.2,10.4
			c-0.9,0.4-1.8,0.6-2.7,0.7l-21.7,1.4c-3.4,0.2-6.5-1.7-7.9-4.8l-3.8-8.9c-1.2-2.9-4-4.8-7.2-4.8l-25.9-0.6c-2,0-4,0.7-5.5,2l-8,7
			c-1.5,1.3-3.5,2-5.5,2l-56.3-1.3c-4-0.1-7.3-3.2-7.8-7.2l-0.9-8.7c-0.2-1.5,0.1-3.1,0.8-4.5l14.4-28.5c1.9-3.8,0.6-8.3-3.1-10.5
			l-30.4-18c-2.4-1.4-3.9-4.1-3.9-6.9v-36.9c0-1.2,0.3-2.5,0.8-3.6l20.9-41.8c0.8-1.6,2.2-3,3.9-3.7l6.8-3.1c1.6-0.7,2.9-2,3.8-3.5
			l12-22.8c1.3-2.4,3.6-4,6.3-4.2l47.3-4.3c0.2,0,0.3,0,0.5,0l54.7-1.8c2.6-0.1,5-1.4,6.5-3.6l3-4.6c2.2-3.4,6.7-4.6,10.4-2.8
			l29.5,15.2c0.4,0.2,0.7,0.4,1,0.6l18.8,13.6c0.2,0.2,0.4,0.3,0.6,0.5l36.7,32.9c2.5,2.2,6,2.7,8.9,1.2l33.6-17
			c0.7-0.4,1.4-0.8,2-1.4l10.3-10c0.9-0.9,1.7-2.1,2.1-3.3l5.8-18.3c1-3.1,0-6.5-2.5-8.6l-24.3-20.4c-2.7-2.3-3.6-6.1-2.2-9.3
			l32.8-76.2c1.5-3.4,0.4-7.4-2.6-9.6l-41.5-30.5C1832.9,1367.6,1833.1,1360.9,1837.6,1357.8z"></path>
			
				
				
			<path id="charlottenburg-wilmersdorf" d="M560.9,1857.7h38.4c2.3,0,4.5,1,6,2.7l11.3,12.9c2.5,2.8,6.6,3.5,9.9,1.8
			l19.7-10.7c1.8-1,4-1.2,6-0.7l31.3,8.9c1,0.3,2,0.4,2.9,0.3l53.2-5c0.3,0,0.5-0.1,0.8-0.1l50-9.8c1.4-0.3,2.9-0.2,4.2,0.3
			l37.7,13.4l56.7,21.4c0.7,0.3,1.4,0.4,2.1,0.5l68.2,6.2c0.8,0.1,1.6,0,2.4-0.2l69.3-15.1l82.7-18.8c1.3-0.3,2.6-0.3,3.8,0.1
			l102.2,27.4c0.7,0.2,1.4,0.3,2.1,0.3h55.5c4.4,0,8-3.6,8-8v-78.6c0-4.1,3.1-7.6,7.3-8l73.9-6.9c4.1-0.4,7.3-3.8,7.3-8v-110.6
			c0-1.9,6.5-50.5,7.4-57.4c0.1-0.6,0.2-1.1,0.4-1.6l3.6-10c1.3-3.7-0.2-7.8-3.6-9.7l-1-0.6c-4.5-2.5-5.4-8.6-1.9-12.4l15.3-16.4
			c4.2-4.5,2-11.8-4-13.3l-21-5c-2.9-0.7-5.2-3-5.9-5.9l-3.9-16.7c-0.5-2.3-2.1-4.3-4.2-5.3l-6.4-3.2c-5.2-2.6-6-9.8-1.4-13.4l3.8-3
			c2.3-1.8,3.4-4.7,2.9-7.5l-1.4-8.8c-0.6-3.9,1.7-7.6,5.4-8.8l1.5-0.5c4.5-1.5,6.7-6.6,4.7-10.9l-6.1-13.2c-0.5-1-1.1-1.9-1.9-2.6
			l-13.8-12.4c-1.5-1.3-2.4-3.1-2.6-5.1l-3.8-35.5c-0.3-2.5-1.7-4.7-3.8-6l-26.9-16.3c-3.4-2-7.7-1.3-10.3,1.7l-9.1,10.7
			c-1,1.2-1.7,2.8-1.9,4.4l-1.8,17.7c-0.4,4.1-3.9,7.2-8,7.2h-6.2c-3.9,0-7.2-2.8-7.9-6.6l-12.9-71.7c-0.6-3.5,1.1-6.9,4.2-8.5
			l14.1-7.3c3.6-1.9,5.2-6.1,3.8-9.9l-0.7-1.9c-1.1-3.1-4.1-5.2-7.4-5.2l-14.4-0.2c-4.4-0.1-7.9-3.7-7.9-8.1l0.2-15.3
			c0.1-3.6,2.4-6.6,5.9-7.6l75.4-20.8c5.5-1.5,7.7-8.1,4.2-12.6l-57.1-73.1c-1.4-1.8-3.6-3-6-3.1l-81.3-3.5h-157.8c-4.4,0-8,3.6-8,8
			v17.2c0,1.8,0.6,3.5,1.7,4.9l10.1,13c1.4,1.8,1.9,4,1.6,6.2l-5.4,32.4c-0.3,1.7,0,3.4,0.7,4.9l9.7,19.3c0.5,0.9,1.1,1.8,1.9,2.5
			l16.4,14.2c1.1,0.9,2.4,1.6,3.8,1.8l11.5,2.2c3.9,0.7,6.7,4.3,6.5,8.2l-0.8,16.1c-0.1,2.4-1.3,4.7-3.3,6.1l-27.3,19.7
			c-1.1,0.8-2.3,1.3-3.6,1.4l-52.9,7.4c-0.2,0-0.3,0-0.5,0l-76.5,6.3c-1,0.1-1.9,0-2.9-0.3l-17.2-4.9c-4.5-1.3-9.1,1.5-10,6.1
			l-2.9,13.8c-1,4.6-5.7,7.4-10.2,6l-19.9-6.1c-0.8-0.2-1.6-0.4-2.4-0.3l-92.4,1.2c-2.4,0-4.6,1.1-6.1,2.9l-13.7,16.6
			c-1.2,1.5-1.9,3.4-1.8,5.3l1.2,44.1c0,0.2,0,0.4,0,0.6l3.6,33.3c0.2,1.5-0.1,3-0.8,4.4l-17.6,36.2c-1.2,2.5-3.7,4.2-6.5,4.5
			l-18,1.6c-3.3,0.3-6.1,2.6-7,5.9l-13.9,51.3c-0.8,3-3.3,5.2-6.3,5.8l-31.1,5.5c-0.4,0.1-0.7,0.1-1,0.3l-33.7,10.8
			c-0.9,0.3-1.8,0.8-2.6,1.4l-26.7,21.7c-0.9,0.8-1.7,1.7-2.2,2.8l-12.6,26.5c-0.4,0.8-0.6,1.6-0.7,2.4l-4.5,35.5
			c-0.1,0.8-0.1,1.6,0,2.3l4.6,27.5c0.1,0.4,0.2,0.8,0.3,1.2l7.4,22.6c0.2,0.7,0.3,1.4,0.4,2l1.2,22.3c0,0.9,0,1.8-0.3,2.6l-5.8,20.9
			c-0.1,0.5-0.2,1-0.3,1.4l-2.8,31.3c0,0.2,0,0.5,0,0.7v30C552.9,1854.2,556.5,1857.7,560.9,1857.7z"></path>
			
				
					
			<path id="mitte" d="M1186.9,1146.9l42.5-74c1.1-1.9,2.9-3.3,5-3.8l29.9-7.3c0.3-0.1,0.5-0.1,0.8-0.2l40.5-5.6
			c2.3-0.3,4.3-1.6,5.6-3.5l10.1-15.5c1.4-2.2,3.8-3.5,6.4-3.6l26.3-1.1c1.6-0.1,3.2-0.6,4.5-1.6l17.2-13.1c2.1-1.6,4.9-2.1,7.4-1.2
			l28.2,9.6l21.4,7.8c0.8,0.3,1.6,0.4,2.4,0.5l71.5,2.6c1.7,0.1,3.3,0.7,4.7,1.7l16,12.7c1.2,0.9,2,2.1,2.5,3.5l3.5,9.5
			c1.9,5.3,8.6,7,12.8,3.3l16.6-14.5c1.3-1.1,3-1.8,4.7-2l20.9-1.5c2.8-0.2,5.3-1.9,6.6-4.4l7.8-15.6c0.3-0.6,0.5-1.2,0.6-1.8
			l6.7-28.9c0.8-3.4,3.7-6,7.3-6.2l39.3-2.6c2.3-0.2,4.6,0.7,6.2,2.4l38.9,39.7c1.2,1.2,2,2.8,2.2,4.5l5.8,41.8c0,0.2,0,0.3,0.1,0.5
			c0.3,4.3,4.7,66,4.7,68.3s31.3,76.3,34.1,82.8c0.1,0.3,0.2,0.6,0.3,0.9l14,49.8c0.4,1.3,0.4,2.8,0.1,4.1l-8.8,35.2
			c-0.9,3.7,0.9,7.6,4.5,9.2l30.3,13.8c0.2,0.1,0.3,0.2,0.5,0.2l38.3,20.5c0.3,0.2,0.7,0.4,1,0.6l50.2,36.9c3,2.2,4.1,6.2,2.6,9.6
			l-32.8,76.2c-1.4,3.2-0.5,7,2.2,9.3l24.3,20.4c2.5,2.1,3.5,5.5,2.5,8.6l-5.8,18.3c-0.4,1.3-1.1,2.4-2.1,3.3l-10.3,10
			c-0.6,0.6-1.2,1-2,1.4l-33.6,17c-2.9,1.5-6.5,1-8.9-1.2l-36.7-32.9c-0.2-0.2-0.4-0.4-0.6-0.5l-18.8-13.6c-0.3-0.2-0.7-0.4-1-0.6
			l-29.5-15.2c-3.7-1.9-8.1-0.7-10.4,2.8l-3,4.6c-1.4,2.2-3.8,3.6-6.5,3.6l-54.7,1.8c-0.2,0-0.3,0-0.5,0l-47.3,4.3
			c-2.7,0.2-5.1,1.8-6.3,4.2l-12,22.8c-0.8,1.6-2.2,2.8-3.8,3.5l-6.8,3.1c-1.7,0.8-3,2.1-3.9,3.7l-18.6,37.1
			c-1.8,3.6-6.1,5.3-9.9,3.9l-141-51.4c-0.3-0.1-0.6-0.2-0.9-0.3l-34-8c-2.9-0.7-5.2-3-5.9-5.9l-3.9-16.7c-0.5-2.3-2.1-4.3-4.2-5.3
			l-6.4-3.2c-5.2-2.6-6-9.8-1.4-13.4l3.8-3c2.3-1.8,3.4-4.7,2.9-7.5l-1.4-8.8c-0.6-3.9,1.7-7.6,5.4-8.8l1.5-0.5
			c4.5-1.5,6.7-6.6,4.7-10.9l-6.1-13.2c-0.5-1-1.1-1.9-1.9-2.6l-13.8-12.4c-1.5-1.3-2.4-3.1-2.6-5.1l-3.8-35.5
			c-0.3-2.5-1.7-4.7-3.8-6l-26.9-16.3c-3.4-2-7.7-1.3-10.3,1.7l-9.1,10.7c-1,1.2-1.7,2.8-1.9,4.4l-1.8,17.7c-0.4,4.1-3.9,7.2-8,7.2
			h-6.2c-3.9,0-7.2-2.8-7.9-6.6l-12.9-71.7c-0.6-3.5,1.1-6.9,4.2-8.5l14.1-7.3c3.6-1.9,5.2-6.1,3.8-9.9l-0.7-1.9
			c-1.1-3.1-4.1-5.2-7.4-5.2l-14.4-0.2c-4.4-0.1-7.9-3.7-7.9-8.1l0.2-15.3c0.1-3.6,2.4-6.6,5.9-7.6l75.4-20.8
			c5.5-1.5,7.7-8.1,4.2-12.6l-57.1-73.1c-1.4-1.8-3.6-3-6-3.1l-68.1-2.9C1187.5,1158.6,1183.9,1152.1,1186.9,1146.9z"></path>
			
				
					
			<path id="treptow-koepenick" d="M2900.6,1858.7l23.9,1.4c4.2,0.2,7.5,3.7,7.5,8v24.6c0,4.4,3.6,8,8,8h13.2
			c4.4,0,8-3.6,8-8v-57.9c0-4.1,3.1-7.5,7.1-8l24.5-2.7c0.8-0.1,1.7-0.3,2.4-0.7l28.4-12.9c0.4-0.2,0.7-0.4,1.1-0.6l25.7-16.9
			c0.2-0.1,0.3-0.2,0.5-0.3l13.6-7.6c1.7-0.9,3.6-1.2,5.5-0.9l22.6,4.5c1.3,0.3,2.5,0.8,3.5,1.7l18,14.7c0.4,0.3,0.8,0.6,1.2,0.8
			l24.9,13.7c0.6,0.4,1.3,0.6,2.1,0.8l21.8,5c0.6,0.1,1.2,0.2,1.8,0.2h26.6c1.2,0,2.3,0.2,3.3,0.7l19.4,8.9c0.6,0.3,1.2,0.7,1.8,1.1
			l31.8,26.5c0.2,0.1,0.3,0.3,0.4,0.4l27.4,26.5c0.5,0.5,1.1,0.9,1.7,1.2l29.1,16.3c0.7,0.4,1.4,0.7,2.2,0.8l25.8,5.6
			c1.5,0.3,2.9,1.1,3.9,2.2l12.4,12.4c1.5,1.5,2.3,3.5,2.3,5.7v20.9c0,0.3,0,0.7,0.1,1l4.9,39.2c0.3,2.6,1.9,4.9,4.2,6.1l13.3,6.9
			c1.4,0.8,3.1,1.1,4.7,0.9l14.3-1.8c3.1-0.4,5.7-2.6,6.6-5.6l5.5-17.7c0.7-2.4,0.3-5-1.2-7.1l-12.1-16.7c-0.1-0.1-0.2-0.3-0.3-0.4
			l-15.4-23.9c-0.6-1-1-2.1-1.2-3.2l-5-36.7c-0.6-4.5,2.6-8.6,7.1-9l6.5-0.7c1.7-0.2,3.4,0.2,4.9,1.1l48.3,28.5
			c0.4,0.2,0.8,0.5,1.1,0.8l21.6,18.3c1.1,0.9,1.9,2.1,2.4,3.4l10,27.6c0.1,0.4,0.3,0.8,0.5,1.1l18.2,33c0.4,0.8,1,1.5,1.6,2.1
			l27.3,24.8c0.6,0.6,1.3,1,2,1.3l39.7,18.1c0.8,0.3,1.6,0.6,2.4,0.7l38.1,4.3c0.6,0.1,1.1,0.2,1.7,0.4l33.7,11.5
			c0.2,0.1,0.3,0.1,0.5,0.2l24.5,10.1c1.2,0.5,2.2,1.2,3,2.2l14.6,17.1c0.4,0.4,0.7,0.9,1,1.4l11.4,21.2c0.6,1.2,1,2.5,1,3.8v17.7
			c0,3,1.7,5.8,4.4,7.2l9.4,4.7c2.7,1.4,4.4,4.1,4.4,7.2v15.7c0,5-4.5,8.7-9.4,7.9l-27.2-4.7c-3.3-0.6-5.9-3.1-6.5-6.4l-6.2-32.1
			c-1-5.1-6.4-7.9-11.1-5.8l-34,15.3c-2.1,1-3.7,2.8-4.4,5l-5.9,19.8c-0.3,0.9-0.4,1.9-0.3,2.9l1.1,14.2c0.4,4.8-3.5,8.8-8.3,8.6
			l-31.4-1.4c-1.1,0-2.2-0.3-3.2-0.8l-42.6-20.9c-3.5-1.7-7.8-0.6-10.1,2.6l-5.4,7.7c-2.8,4-1.4,9.6,3,11.7l44.1,21.7
			c1,0.5,2.1,0.8,3.2,0.8l33.2,1.4c4.1,0.2,7.3,3.4,7.6,7.4l1,14.6c0.2,2.4-0.8,4.8-2.6,6.5l-26.8,24.4c-0.3,0.3-0.6,0.6-0.8,0.9
			l-25.1,31.1c-0.7,0.8-1.2,1.8-1.5,2.9l-5.5,19.6c-0.3,1.1-0.4,2.3-0.2,3.4l4,25.6c0.2,1.2,0.7,2.4,1.4,3.4l21.2,29.7
			c0.4,0.5,0.7,1.1,0.9,1.7l11.9,29.8c0.3,0.9,0.5,1.8,0.6,2.7l0.8,22.3c0,1.3-0.2,2.5-0.7,3.7l-11.6,24.9c-0.3,0.5-0.6,1.1-0.9,1.5
			l-23.2,29.8c-1.2,1.5-2.9,2.6-4.9,3l-35.1,6.5c-0.8,0.1-1.6,0.2-2.3,0.1l-30.3-3.4c-1-0.1-1.9,0-2.9,0.2l-32.6,8.4
			c-1.1,0.3-2.1,0.8-3,1.5l-28.4,22.6c-0.9,0.7-1.6,1.5-2.1,2.5l-11.9,22.2c-1.2,2.3-3.6,3.9-6.2,4.2l-16.9,1.9
			c-2.3,0.3-4.4,1.5-5.7,3.4l-22.6,32.3c-0.6,0.9-1.4,1.6-2.2,2.2l-20.3,13c-0.8,0.5-1.6,0.9-2.5,1.1l-10.1,2.3
			c-4.6,1-7.3,5.8-5.8,10.3l2.3,6.9c0.8,2.4,2.7,4.3,5.2,5.1l27.6,8.7c0.3,0.1,0.6,0.2,0.9,0.2l23.4,4.4c3.2,0.6,5.7,3.1,6.4,6.3
			l7,35.6c0.1,0.7,0.2,1.5,0.1,2.3l-3.4,36c-0.1,1.4-0.6,2.7-1.4,3.8l-30.2,43.3c-1.5,2.1-3.9,3.4-6.6,3.4h-23.8
			c-1.4,0-2.8-0.4-4-1.1l-25.5-14.7c-1.8-1-3.9-1.3-5.9-0.8l-32.2,7.8c-1.4,0.3-2.7,1.1-3.8,2.1l-31.2,31.2
			c-0.7,0.7-1.2,1.4-1.6,2.3l-19,40.7c-0.3,0.7-0.5,1.4-0.7,2.1l-5,30.7c-0.2,1.3-0.7,2.5-1.5,3.5l-26.6,35.2
			c-0.3,0.4-0.6,0.9-0.9,1.4l-15.1,31.9c-0.1,0.2-0.2,0.4-0.2,0.6l-20.7,53.9c-0.2,0.4-0.3,0.9-0.4,1.3l-5.9,29.5
			c-0.7,3.7-4,6.4-7.8,6.4h-16.4c-1.7,0-3.4-0.5-4.7-1.6l-27.5-20.2c-0.9-0.7-1.7-1.6-2.3-2.6l-19.3-35.3c-0.6-1-0.9-2.1-1-3.3
			l-4.4-60.7c0-0.4,0-0.8,0-1.2l3.5-48.6c0.1-0.7,0.2-1.4,0.4-2.1l11.9-34.1c0.3-0.9,0.8-1.8,1.5-2.5l25.3-29.7
			c0.1-0.2,0.3-0.3,0.4-0.5l15-15c1.4-1.4,2.2-3.2,2.3-5.2l1.3-22.6c0.2-4.1-2.7-7.8-6.8-8.4l-11.1-1.6c-3.7-0.6-6.6-3.6-6.8-7.4
			l-2.7-40.8c-0.3-4.3-3.9-7.5-8.1-7.5l-38.4,0.7c-2.7,0-5.2-1.2-6.7-3.4l-18.2-26.3c-2.3-3.3-6.8-4.4-10.3-2.5l-107.8,57.5
			c-5.2,2.7-11.4-0.8-11.8-6.6l-2.8-51.2c-0.1-1.9-0.9-3.8-2.3-5.2l-55.5-56.3c-1.2-1.2-1.9-2.7-2.2-4.3l-7.2-43.1
			c-0.8-4.8-5.7-7.8-10.4-6.3l-139.6,46c-0.9,0.3-1.9,0.4-2.8,0.4l-155.3-6c-4.7-0.2-8.2-4.3-7.6-8.9l9.4-80.7
			c0.7-6-5.2-10.5-10.8-8.4l-34.7,13.3c-2.1,0.8-4.3,0.7-6.3-0.3l-59.3-28.5c-4.3-2.1-5.8-7.4-3.4-11.4l19.4-31.7
			c0.4-0.7,0.7-1.4,0.9-2.1l8.7-32.1c0.1-0.4,0.2-0.8,0.2-1.2l5.2-48.1c0.1-1,0-2.1-0.3-3l-9.4-33.4c-0.1-0.5-0.3-1-0.6-1.5
			l-12.1-23.4c-0.8-1.5-1.1-3.3-0.8-5l4.2-24.7c0.5-2.9-0.6-5.8-2.9-7.6l-9.1-7.3c-0.6-0.5-1.2-0.8-1.9-1.1l-48.5-20.3
			c-0.5-0.2-1-0.5-1.4-0.8l-50.3-34.1c-0.2-0.1-0.3-0.2-0.5-0.3l-51.9-40.9l-38.4-29.2l-47.3-39.1c-0.2-0.1-0.3-0.2-0.5-0.4
			l-33.7-24c-0.5-0.4-1-0.8-1.4-1.3l-29.4-33.7c-0.7-0.8-1.2-1.7-1.5-2.7l-18.5-54c-1.8-5.4,2.3-10.9,8-10.6l72.4,3.7
			c2.7,0.1,5.3-1.1,6.9-3.4l24-33.8c1.7-2.3,1.9-5.4,0.7-8l-21.4-46c-0.3-0.6-0.7-1.2-1.1-1.7l-19.7-23.8c-1-1.2-1.6-2.7-1.8-4.3
			l-4.1-40.2c-0.1-1.4-0.6-2.7-1.4-3.8l-21.3-29.8c-0.2-0.3-0.5-0.6-0.8-0.9l-33.6-35.4c-1.5-1.5-3.6-2.4-5.7-2.4h-14.8
			c-1.9,0-3.6-0.6-5.1-1.8l-35.8-29.4c-2-1.6-4.6-2.2-7.1-1.6l-16.2,4.2c-2.8,0.7-5.8-0.1-7.8-2.2l-12.3-12.8
			c-2.7-2.8-3-7.1-0.7-10.2l27.4-38.2c2.1-2.9,5.8-4.1,9.1-2.9l11.2,3.9c2.6,0.9,5.5,0.4,7.7-1.3l21.6-17.5c2.5-2.1,6.1-2.4,8.9-0.8
			l97.7,54.5c0.5,0.3,1.1,0.7,1.5,1.1l39.5,36.2c2.2,2,5.4,2.7,8.2,1.6l35.8-13.6c3.1-1.2,6.7-0.3,8.9,2.2l17.9,20.7
			c1.4,1.6,2.1,3.7,1.9,5.8l-1,13.4c-0.3,4.4,2.9,8.2,7.3,8.6l32.1,2.7c2.4,0.2,4.6,1.5,6,3.5l16.2,23.9c0.6,0.8,1,1.8,1.2,2.7
			l6.4,28.6c0.8,3.8,4.3,6.4,8.1,6.2l41.8-1.5c0.6,0,1.2,0,1.8,0.1l35.2,6.6c2.1,0.4,3.9,1.5,5.1,3.3l20.1,28.4l15.8,25
			c3,4.7,9.7,5,13.1,0.6l38.7-50.3c1.7-2.2,4.5-3.4,7.3-3.1l40.7,4.9c0.3,0,0.6,0.1,1,0.1h27.6c0.2,0,0.3,0,0.5,0l59,3.5
			c1.3,0.1,2.6-0.2,3.8-0.7l47.5-21.7c1.8-0.8,3.2-2.3,4-4l14.7-33.2c0.9-2.1,2.7-3.7,4.9-4.4l15.3-4.9c0.9-0.3,1.9-0.4,2.8-0.4
			l34.7,1.7c1.5,0.1,2.9,0.5,4.1,1.4l31.5,21.3c0.3,0.2,0.5,0.4,0.8,0.6l37.9,33c0.4,0.3,0.8,0.6,1.2,0.9l16.7,9.8
			c0.9,0.5,2,0.9,3,1l15.7,2c2.8,0.4,5.2,2.2,6.4,4.8l4.2,9.7c1.3,3.1,4.5,5.1,7.9,4.8l18.5-1.4
			C2899.9,1858.7,2900.2,1858.7,2900.6,1858.7z"></path>
			
				
				
			<path id="neukoelln" d="M2360.9,2481.7l22.3-36.3c0.4-0.7,0.7-1.4,0.9-2.1l8.7-32.1c0.1-0.4,0.2-0.8,0.2-1.2l5.2-48.1
			c0.1-1,0-2.1-0.3-3l-9.4-33.4c-0.1-0.5-0.3-1-0.6-1.5l-12.1-23.4c-0.8-1.5-1.1-3.3-0.8-5l4.2-24.7c0.5-2.9-0.6-5.8-2.9-7.6
			l-9.1-7.3c-0.6-0.5-1.2-0.8-1.9-1.1l-48.5-20.3c-0.5-0.2-1-0.5-1.4-0.8l-50.3-34.1c-0.2-0.1-0.3-0.2-0.5-0.3l-51.9-40.9
			l-38.4-29.2l-47.3-39.1c-0.2-0.1-0.3-0.2-0.5-0.4l-33.7-24c-0.5-0.4-1-0.8-1.4-1.3l-29.4-33.7c-0.7-0.8-1.2-1.7-1.5-2.7l-18.5-54
			c-1.8-5.4,2.3-10.9,8-10.6l72.4,3.7c2.7,0.1,5.3-1.1,6.9-3.4l24-33.8c1.7-2.3,1.9-5.4,0.7-8l-21.4-46c-0.3-0.6-0.7-1.2-1.1-1.7
			l-19.7-23.8c-1-1.2-1.6-2.7-1.8-4.3l-4.1-40.2c-0.1-1.4-0.6-2.7-1.4-3.8l-21.3-29.8c-0.2-0.3-0.5-0.6-0.8-0.9l-34.4-35.6
			c-1.5-1.5-3.6-2.4-5.7-2.4h-14.8c-1.9,0-3.6-0.6-5.1-1.8l-35.8-29.4c-2-1.6-4.6-2.2-7.1-1.6l-16.2,4.2c-2.8,0.7-5.8-0.1-7.8-2.2
			l-16.1-16.8c-0.5-0.5-1.1-1-1.7-1.3l-88.5-52.7c-3.1-1.8-7-1.4-9.6,1.1l-0.3,0.3c-2.4,2.2-3.1,5.7-2,8.7l23.4,60.6
			c0.9,2.4,0.6,5.1-0.8,7.3l-5.4,8.2c-2,3.1-5.8,4.4-9.3,3.2l-31.7-10.8c-0.2-0.1-0.4-0.1-0.6-0.2l-37.6-9.5c-3.4-0.9-7,0.6-8.8,3.6
			l-13.3,21.9c-0.9,1.5-1.3,3.2-1.1,4.9l4.4,46.1c0.4,3.9,3.6,7,7.5,7.2l27.3,1.6c3.6,0.2,6.6,2.8,7.3,6.3l4.8,22.7
			c0.1,0.4,0.1,0.7,0.2,1.1l3.3,44.3l4.4,39.4c0.5,4.3,4.4,7.5,8.7,7.1l15-1.4c1.7-0.2,3.3,0.2,4.8,1l2.5,1.4c2.7,1.6,4.2,4.6,4,7.7
			l-3.8,41.1c-0.3,3.1,1.2,6.1,3.9,7.7l15.1,8.8c3.8,2.2,5.1,7,2.9,10.9l-2.6,4.6c-1.2,2.1-3.3,3.6-5.7,4l-16.5,2.6
			c-2.7,0.4-5,2.2-6.1,4.6l-32.5,72.2c-0.1,0.3-0.3,0.7-0.4,1l-16.5,55.8c-0.1,0.2-0.1,0.4-0.1,0.5l-11.4,51.5l-20.4,75.5
			c-0.1,0.5-0.3,0.9-0.5,1.3l-12.3,26c-2.3,4.9,0.8,10.6,6.1,11.3l1.6,0.2c4.2,0.6,7.1,4.3,6.8,8.5l-0.9,11.6
			c-0.3,3.5-2.8,6.4-6.2,7.2l-19.5,4.4c-4.5,1-7.2,5.7-5.9,10.1l12.3,40.9c1.1,3.7,4.8,6.1,8.7,5.6l31-4c1.9-0.3,3.9,0.2,5.5,1.3
			l40.9,27.9c2.3,1.6,5.2,1.8,7.7,0.7l229.8-101.1c1.8-0.8,3.8-0.9,5.7-0.3l24.3,7.9c2.4,0.8,4.3,2.7,5.1,5.1l66.8,200.4
			c1.3,3.9,5.3,6.2,9.3,5.3l187-41.6C2357.9,2484.8,2359.7,2483.5,2360.9,2481.7z"></path>
			
				
		</svg>
    </div>
    
  )
}
