import React from 'react'

import {
    createStyles,
    Title,
    Grid,
    Col,
    rem,
    Card,
    List,
    ThemeIcon,
    Text,
    Space,
    Image,
    Flex,
    Container
  } from '@mantine/core';

  import { CircleCheck} from 'tabler-icons-react';


  
  const useStyles = createStyles((theme) => ({
    wrapper: {
      padding: `calc(${theme.spacing.xl} * 2) ${theme.spacing.xl}`,
    },
  
    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: rem(36),
      fontWeight: 700,
      lineHeight: 1.1,
      marginBottom: theme.spacing.md,
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },

    card: {
        border: `${rem(1)} solid ${
          theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
        }`,
      },
    
      cardTitle: {
        '&::after': {
          content: '""',
          display: 'block',
          backgroundColor: '#726B9B',
          width: rem(45),
          height: rem(2),
          marginTop: theme.spacing.sm,
        },
      },
  }));
  

  
  export function AboutUs() {
    const { classes } = useStyles();


  
    return (
      <Container py="xl">

        <Title className={classes.title} order={2}>
        Herzlich willkommen bei ALEF – einer Initiative des studentischen Forums für Partizipation! 
        </Title>

        <Grid dir='column-reverse' display="flex">
          <Col sm={8}>
         
          <Text>
            Wir sind ein studentischer Verein, der sich die Förderung zivilgesellschaftlicher Partizipation zum Ziel gesetzt hat.  Wir sind der Überzeugung, dass eine starke und engagierte Zivilgesellschaft eine wichtige Rolle in einer lebendigen Demokratie spielt. Mit unseren Projekten und Initiativen, erarbeitet aus Meinungen und Ideen von Mitgliedern und Interessierten, möchten wir eine integrative Zusammenarbeit hinsichtlich eines gesamtgesellschaftlichen Miteinanders schaffen. 
            </Text>
          </Col>

          <Col sm={4}>
        
            <Image src="./team.svg"  width={270} />

          </Col>


          <Col sm={4} orderSm={1} order={2}>
            <Flex  display="flex"  h="100%" justify="center" align="flex-start"  direction="column">
              <Image  src="./Alefimg.svg" maw={250} />
            </Flex>
          
            
  
          </Col>

          <Col sm={8}  orderSm={2}  order={1}>
           
              <Title order={2}>Das Projekt "ALEF"</Title>
              <Text>
              Unser Ziel ist es, Menschen mit Fluchterfahrung den Start in ein neues Leben zu erleichtern, indem wir ihnen helfen, erste Schritte in der deutschen Sprache zu machen. Mit zusätzlichen Sprachkursangeboten bieten wir eine niederschwellige Brückenlösung für das Niveau A1.1 an. Zusammen mit Studierenden fördern wir ein partizipatives Miteinander und bewirken eine positive Veränderung in unserer Gesellschaft, die auf Respekt, Vertrauen und Empathie beruht.
            </Text>

            <Space h="lg" />
            
            <Text>
Das Hauptziel der Initiative ALEF ist es, Kinder, Jugendliche und Erwachsene, die sich noch im Asylverfahren befinden, im Hinblick auf ihre Deutschkenntnisse zu fördern und ihnen so eine bessere Partizipation an der deutschen Gesellschaft zu ermöglichen.
Durch den Schulplätze mangel ist es um so wichtiger, Kindern die deutsche Sprache näherzubringen. Hierfür werden wir gezielt eng mit den Unterkünften zusammen arbeiten und vor Ort Deutschkurse anbieten, um den späteren Einstieg in die Schule bzw. Willkommensklassen zu erleichtern.
            </Text>
  
          </Col>
          </Grid>

          <Grid >
      
          
         
        <Col md={12} mt={25}>
            <Card radius="md" className={classes.card} padding="md">
            <Text fz="lg" fw={700} className={classes.cardTitle} >Kurzer Überblick zur Durchführung des Projekts:</Text>
            <Space h="lg" />

            <Grid>
              <Col sm={8}>
                <List
                spacing="xs"
                size="md"
                center
                icon={
                    <ThemeIcon color="violet" size={24} radius="xl">
                        <CircleCheck  size="1rem" />
                    </ThemeIcon>
                    }
                 >
                    <List.Item>Pro Kurs 10-15 Teilnehmer, vorerst zwei mal pro Woche</List.Item> 
                    <List.Item>Schwerpunktsetzung: Deutsch Anfangskurs (A1)</List.Item> 
                    <List.Item>Die Unterrichtsstunden werden jeweils von zwei Studierenden geleitet</List.Item>
                    <List.Item>Umfangreiche Einführung in die Gestaltung und Durchführung der Sprachkurse sowie Workshops im Bereich interkulturelle Kompetenzen für die Studierenden</List.Item>
                    <List.Item>Regelmäßige Supervisionen und Feedback-Besprechungen mit den Studierenden</List.Item>
                    <List.Item>Es sind keine Vorkenntnisse erforderlich. Geduld, Empathie, die Beherrschung der deutschen Sprache sind erwünscht</List.Item>
                </List>
                </Col>
                

                <Col sm={4}>
                <Image src="./check.svg" maw={250} />
                </Col>
              </Grid>
            </Card>
            </Col>
        </Grid>
      </Container>
    );
  }