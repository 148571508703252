import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Start from './Pages/Start';
import Engagieren from './Pages/Engagieren';
import ParticipateTeacher from './Components/ParticipateTeacher';
import Standorte from './Pages/Standorte';
import Kontakt from './Components/Kontakt';
import About from './Pages/About';
import EventDetails from './Components/News/Newsdetails';
import eventsData from './Components/News/NewsData';
import { Box, Container } from '@mantine/core';
import NewsList from './Components/News/NewsList';
import { Team } from './Pages/Team';

export default function AllRoutes() {
  const upcomingEvents = eventsData.filter(
    (event) => new Date(event.date) >= new Date()
  );
  const pastEvents = eventsData.filter(
    (event) => new Date(event.date) < new Date()
  );

  return (
    <Box>
    <Routes>
      <Route path="/start" element={<Start />} />
      <Route path="/engagieren" element={<Engagieren />} />
      <Route path="/engagieren/lehrer" element={<ParticipateTeacher />} />
      <Route path="/about" element={<About />} />
      <Route path="/standorte" element={<Standorte />} />
      <Route path="/kontakt" element={<Kontakt />} />
      <Route path="/team" element={<Team />} />
      <Route
        path="/veranstaltungen"
        element={
          <Container>
            <h2>Anstehende Veranstaltungen</h2>
            <NewsList news={upcomingEvents} />
            <h2>Vergangene Veranstaltungen</h2>
            <NewsList news={pastEvents} />
          </Container>
        }
      />
      <Route path="/veranstaltungen/:eventId" element={<EventDetails />} />
      <Route path="*" element={<Navigate to="/start" />} />
    </Routes>
    </Box>
  );
}