import React from 'react'
import { Header } from '../Components/Header'
import Partner from '../Components/Partner'
import { Participate } from '../Components/Participate'
import { Container, Paper } from '@mantine/core'
import { News } from '../Components/News'
import TitleText from '../Components/TitleText'
import { Box } from 'tabler-icons-react'
import Events from '../Components/Events'

export default function Start() {

  interface MarkdownObject {
    title: string;
    description: string | React.ReactElement;
    button?: {
        text: string;
        onClick: () => void;
        color?: string;
        size?: string;
        variant?: string;
        bg?:string;
        radius?:string;
        link:string;
      };
      
  }

  const participateData : MarkdownObject [] = [
    {
      title: 'Du möchtest unterrichten?',
      description:
      <div dangerouslySetInnerHTML={{ __html:"<p>Du möchtest dich sozial engagieren, einem starken Netzwerk anschließen und Leistungspunkte für dein Studium sammeln?</p> <b>Dann schließ dich uns an und werde Teil des Teams !</b> <p>Wir sind eine Gruppe von engagierten Studierenden, die sich für ein stärkeres Miteinander einsetzen.</p>"}} />,
      button: {
        text: 'Als Sprachhelfer*in anmelden!',
        onClick: () => console.log('Button clicked'),
        color: 'dark.5',
        size: 'sm',
        variant: 'filled',
        radius: 'xl',
        link:"/engagieren/lehrer"
      }

    },
    {
      title: 'Du bist Betreuer*in in einer Unterkunft und möchtest Personen anmelden?',
      description:
      <div dangerouslySetInnerHTML={{ __html:"<p>Wir bieten: </p> <ul><li>Deutsch als Zweitsprache für Geflüchtete Jugendliche (11-17 Jahren)<li>Deutsch als Zweitsprache für alphabetisierte Erwachsene (ab 18 Jahren)<li>Deutsch als für nicht-latinisierte Erwachsene (ab 18 Jahren)</ul>"}} />,
  
      button: {
        text: 'Schüler*innen anmelden!',
        onClick: () => console.log('Button clicked'),
        color: 'dark.5',
        size: 'sm',
        variant: 'filled',
        radius: 'xl',
        link:"/kontakt"
      }
    }
  ];


  return (
    <>
        <Header/>
        <Paper bg="#f8f9fa" sx={{overflow: "hidden"}}>
        <svg preserveAspectRatio="none" viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg" className="mantine-kalks6"><path d="M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z"></path></svg>

        
        <Partner/>

        {/*}

        <TitleText title="Anstehende Veranstaltungen" subtitle="Hier findestet du alle Veranstaltungen, auf denen du herzlich willkommen bist!" />
         <Events/>
               */}



        
        </Paper>

        <Container py="xl">
        <TitleText title="Nachrichtenüberblick" subtitle="Hier findestet du alle wichtigen Nachrichten über das Projekt ALEF" />
          <News/>
        </Container>

        


        <Paper sx={{overflow: "hidden"}}>
        {/*<svg preserveAspectRatio="none" viewBox="0 0 1200 120" xmlns="http://www.w3.org/2000/svg" className="mantine-1f6zvz8"><path d="M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z"></path></svg>
        */}

        <Container  py="xl" >
          <TitleText title="Werde Teil eines großen Netzwerks!" subtitle="Melde dich als Sprachhelfer*in an oder melde Schüler*innen zum Unterricht an." />
          <Participate data={participateData} />
          </Container>
        </Paper>


        
        
    </>
  )
}
